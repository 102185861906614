var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.malli.registry.js");
'use strict';var QK,RK,SK,nja,oja,WK,pja,ZK,$K,aL,bL,qja,cL,dL,fL,gL,TK,hL,rja,iL,sja,jL,kL,lL,tja,mL,nL,uja,oL,vja,pL,qL,wja,sL,xja,yja,zja,uL,vL,wL,xL,yL,zL,AL,BL,Aja,CL,DL,EL,GL,HL,Bja,IL,JL,KL,LL,Cja,ML,NL,OL,PL,Dja,QL,RL,SL,TL,Eja,UL,Fja,VL,WL,XL,Gja,YL,ZL,$L,aM,cM,dM,Hja,eM,fM,Ija,gM,hM,iM,jM,kM,Jja,lM,Kja,mM,nM,pM,Lja,qM,rM,sM,Mja,vM,wM,Nja,Oja,xM,zM,AM,BM,Pja,EM,FM,Qja,Rja,Sja,GM,HM,IM,JM,KM,LM,Tja,MM,Uja,NM,OM,QM,SM,TM,UM,VM,Vja,Wja,XM,Xja,Yja,YM,ZM,$M,aN,bN,Zja,cN,$ja,aka,dN,eN,fN,gN,hN,
bka,cka,dka,iN,eka,jN,fka,lN,mN,gka,oN,pN,qN,rN,hka,uN,ika,jka,vN,wN,xN,kka,yN,zN,AN,BN,lka,DN,EN,FN,GN,HN,mka,IN,JN,nka,KN,LN,MN,NN,ON,PN,RN,oka,SN,pka,TN,UN,VN,qka,rka,ska,XN,YN,ZN,tka,$N,aO,bO,cO,uka,vka,eO,wka,xka,fO,gO,hO,iO,jO,yka,kO,lO,mO,nO,zka,oO,pO,qO,rO,tO,Aka,uO,vO,Bka,wO,xO,yO,Cka,zO,Dka,BO,Eka,CO,DO,EO,FO,GO,HO,IO,Fka,Gka,Hka,JO,Ika,KO,LO,Jka,Kka,MO,NO,OO,Lka,PO,Mka,QO,Nka,Oka,RO,Pka,Qka,Rka,UO,VO,Ska,WO,XO,ZO,Tka,$O,Uka,aP,bP,cP,Vka,dP,eP,fP,gP,Wka,iP,jP,Xka,kP,lP,mP,nP,oP,pP,qP,rP,
Yka,sP,tP,Zka,uP,$ka,vP,wP,xP,ala,yP,zP,bla,cla,AP,BP,CP,dla,ela,DP,fla,gla,hla,FP,ila,HP,jla,kla,IP,JP,lla,mla,nla,KP,LP,MP,NP,OP,PP,QP,ola,RP,SP,pla,TP,qla,rla,sla,UP,VP,WP,tla,XP,YP,ula,ZP,vla,$P,wla,xla,bQ,cQ,dQ,eQ,fQ,yla,gQ,hQ,zla,iQ,jQ,kQ,lQ,mQ,nQ,oQ,pQ,Ala,qQ,rQ,tQ,uQ,vQ,wQ,xQ,yQ,zQ,AQ,BQ,CQ,Bla,DQ,EQ,FQ,GQ,HQ,Cla,IQ,JQ,KQ,LQ,Dla,NQ,Ela,Fla,PQ,Gla,QQ,Hla,RQ,Ila,Jla,SQ,Kla,TQ,VQ,WQ,Lla,Mla,$Q,aR,Nla,Ola,bR,cR,dR,eR,fR,Pla,gR,hR,jR,kR,Qla;
$CLJS.PK=function(a){var b=$CLJS.Cn.g(a,null),c=$CLJS.nE(b,$CLJS.zr,function(d){return $CLJS.Im(d,$CLJS.xf)});return function(){function d(k,l,m){return(l=$CLJS.y(c.j?c.j(k,l,m):c.call(null,k,l,m)))?new $CLJS.h(null,3,[$CLJS.Qk,b,$CLJS.dj,k,$CLJS.Hr,l],null):null}function e(k){return f.j(k,$CLJS.xf,$CLJS.xf)}var f=null;f=function(k,l,m){switch(arguments.length){case 1:return e.call(this,k);case 3:return d.call(this,k,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
QK=function(a,b){return $CLJS.td(a)?$CLJS.K.g(a,b):a};RK=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.Lm($CLJS.Cn.g($CLJS.Qk.h(a),null));if($CLJS.n(b)){var e=QK($CLJS.Es.h(b),c);$CLJS.n(e)?(e=$CLJS.Ap(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:QK($CLJS.ts.h(b),c)}return null};SK=function(a,b){return $CLJS.rd(a)||$CLJS.hl(a)?$CLJS.K.g(a,b):$CLJS.sd(a)?$CLJS.K.g($CLJS.yf(a),b):null};
nja=function(a,b){a=$CLJS.O(a);var c=$CLJS.K.g(a,$CLJS.Qk),d=$CLJS.K.g(a,$CLJS.Ri);b=$CLJS.O(b);var e=$CLJS.K.j(b,$CLJS.Hr,$CLJS.lda),f=$CLJS.K.j(b,$CLJS.gda,!0),k=$CLJS.K.g(b,$CLJS.Fs),l=$CLJS.K.j(b,$CLJS.Cs,$CLJS.As),m=RK(a,$CLJS.Cp.h(c),k,b);if($CLJS.n(m))return m;m=RK(a,$CLJS.Tr.h(c),k,b);if($CLJS.n(m))return m;m=RK(a,e.h?e.h(d):e.call(null,d),k,b);if($CLJS.n(m))return m;m=RK(a,function(){var t=$CLJS.on.h(c);return e.h?e.h(t):e.call(null,t)}(),k,b);if($CLJS.n(m))return m;m=RK(a,$CLJS.Cp.h(c),
l,b);if($CLJS.n(m))return m;m=RK(a,$CLJS.Tr.h(c),l,b);if($CLJS.n(m))return m;d=RK(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=RK(a,function(){var t=$CLJS.on.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;k=$CLJS.n(f)?RK(a,e.h?e.h($CLJS.ys):e.call(null,$CLJS.ys),k,b):f;return $CLJS.n(k)?k:$CLJS.n(f)?RK(a,e.h?e.h($CLJS.ys):e.call(null,$CLJS.ys),l,b):f};
oja=function(a,b,c){a=$CLJS.Q;var d=$CLJS.Wf.g;var e=$CLJS.Nk.h(b);var f=$CLJS.O(b);var k=$CLJS.K.g(f,$CLJS.Qk),l=$CLJS.O(c);f=$CLJS.K.g(l,$CLJS.Fs);l=$CLJS.K.j(l,$CLJS.Cs,$CLJS.As);k=$CLJS.Cp.h(k);f=QK($CLJS.Bs.h(k),f);f=$CLJS.n(f)?f:QK($CLJS.Bs.h(k),l);d=d.call($CLJS.Wf,e,f);return new $CLJS.P(null,2,5,a,[d,nja(b,c)],null)};
$CLJS.UK=function(a){var b=$CLJS.O(a),c=$CLJS.K.g(b,$CLJS.dj);a=$CLJS.K.g(b,$CLJS.Hr);var d=$CLJS.O(null),e=$CLJS.K.j(d,$CLJS.hda,$CLJS.yi),f=$CLJS.K.j(d,$CLJS.jda,oja);return $CLJS.n(a)?$CLJS.cb(function(k,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);l=$CLJS.R.j(l,$CLJS.yi,m);l=e.h?e.h(l):e.call(null,l);return TK(k,c,t,l)},null,a):null};
WK=function(a,b,c){var d=$CLJS.Bz($CLJS.q($CLJS.$C),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.zh.v($CLJS.$C,$CLJS.VK,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null),c);return c};$CLJS.XK=function(a){return WK($CLJS.Nj,a,function(){return $CLJS.oE.h(a)})};pja=function(){var a=YK;return WK($CLJS.zr,a,function(){var b=$CLJS.oE.h(a),c=$CLJS.PK(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
ZK=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,$CLJS.he(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,a],null):a],null)};$K=function(a){if($CLJS.vd(a)){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);switch(b instanceof $CLJS.M?b.S:null){case "optional":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,ZK(c)],null)],null);case "rest":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,ZK(c)],null);default:return ZK(a)}}else return ZK(a)};
aL=function(a,b){var c=$CLJS.vd(b);return c?(c=$CLJS.z(b)instanceof $CLJS.M)?$CLJS.qd(a)?(b=$CLJS.z(b),a=$CLJS.Wg(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.z(b)):c:c};
bL=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ts,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.Qe(aL,a)],null),$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wq,new $CLJS.P(null,2,5,$CLJS.Q,["tag",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,a],null)],null)],null),function(){return function e(d){return new $CLJS.ie(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.wd(f)){var k=$CLJS.ic(f),l=$CLJS.D(k),m=$CLJS.le(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.id(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,2,5,$CLJS.Q,[v,$K(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.oe($CLJS.qe(m),e($CLJS.jc(f))):$CLJS.oe($CLJS.qe(m),null)}k=$CLJS.z(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[m,$K(k)],null),e($CLJS.Hc(f)))}return null}},null,null)}($CLJS.Wr(2,2,b))}())],null)};qja=function(a){return $CLJS.vd(a)&&$CLJS.z(a)instanceof $CLJS.M?$CLJS.z(a):null};
cL=function(a){return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.wi,qja,$CLJS.ts,["valid instance of one of these MBQL clauses: ",$CLJS.hs(", ",$CLJS.cf.g($CLJS.z,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.ie(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.wd(e)){var f=$CLJS.ic(e),k=$CLJS.D(f),l=$CLJS.le(k);a:for(var m=0;;)if(m<k){var t=$CLJS.id(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,
$CLJS.he(t)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.oe($CLJS.qe(l),d($CLJS.jc(e))):$CLJS.oe($CLJS.qe(l),null)}f=$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.he(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,f],null):f],null),d($CLJS.Hc(e)))}return null}},null,null)}(a)}())};
dL=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.OK],null)],null)};$CLJS.eL={};fL={};gL={};$CLJS.VK=function VK(a,b,c){var e=$CLJS.y(b);b=$CLJS.z(e);var f=$CLJS.B(e);if(f){e=$CLJS.R.j;var k=$CLJS.K.g(a,b);c=VK.j?VK.j(k,f,c):VK.call(null,k,f,c);a=e.call($CLJS.R,a,b,c)}else a=$CLJS.R.j(a,b,c);return a};
TK=function TK(a,b,c,d){var f=$CLJS.y(c);c=$CLJS.z(f);var k=$CLJS.B(f),l=SK(b,c);f=$CLJS.n(a)?a:$CLJS.sd(b)?$CLJS.xf:$CLJS.ud(b)?$CLJS.N:$CLJS.gd(b);return $CLJS.n($CLJS.n(c)?$CLJS.zs.h($CLJS.md(f)):c)?a:$CLJS.n(c)?(b=SK(f,c),d=TK.v?TK.v(b,l,k,d):TK.call(null,b,l,k,d),$CLJS.ml(c)&&$CLJS.sd(f)&&c>$CLJS.D(f)&&(b=$CLJS.Ze(c-$CLJS.D(f),null),b=$CLJS.bf.g(f,b),f=null==f||$CLJS.zd(f)?b:$CLJS.Wf.g($CLJS.gd(f),b)),null==f||$CLJS.hl(f)?$CLJS.R.j(f,c,d):$CLJS.rd(f)?$CLJS.Yd.g(f,d):$CLJS.Ne.g($CLJS.U,$CLJS.R.j($CLJS.yf(f),
c,d))):$CLJS.td(a)?(c=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ida],null),TK.v?TK.v(f,b,c,d):TK.call(null,f,b,c,d)):$CLJS.n($CLJS.zs.h($CLJS.md(f)))?$CLJS.Yd.g(f,d):$CLJS.vd($CLJS.Be(f))?f:$CLJS.ld(new $CLJS.P(null,1,5,$CLJS.Q,[d],null),new $CLJS.h(null,1,[$CLJS.zs,!0],null))};hL=new $CLJS.M("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);rja=new $CLJS.r("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
iL=new $CLJS.M("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);sja=new $CLJS.r("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);jL=new $CLJS.M("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);kL=new $CLJS.M("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);lL=new $CLJS.M("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);
tja=new $CLJS.r("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);mL=new $CLJS.M("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);nL=new $CLJS.M("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);uja=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);oL=new $CLJS.M("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);
vja=new $CLJS.M(null,"from","from",1815293044);pL=new $CLJS.M("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);qL=new $CLJS.r(null,"does-not-contain","does-not-contain",1365584674,null);wja=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);$CLJS.rL=new $CLJS.M(null,"snippet-name","snippet-name",819240328);sL=new $CLJS.M("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);
xja=new $CLJS.r("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);yja=new $CLJS.r("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.tL=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);zja=new $CLJS.r("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);uL=new $CLJS.M("location","zip_code","location/zip_code",1641155222);
vL=new $CLJS.r(null,"ExpressionArg","ExpressionArg",1060743736,null);wL=new $CLJS.r(null,"get-hour","get-hour",-2031721710,null);xL=new $CLJS.M("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);yL=new $CLJS.r(null,"ag:var","ag:var",-1804440286,null);zL=new $CLJS.M("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);
AL=new $CLJS.M("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);BL=new $CLJS.M("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);Aja=new $CLJS.M(null,"lon-max","lon-max",1590224717);CL=new $CLJS.M("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);DL=new $CLJS.r(null,"stddev","stddev",775056588,null);EL=new $CLJS.M("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);
$CLJS.FL=new $CLJS.M(null,"snippet","snippet",953581994);GL=new $CLJS.r("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);HL=new $CLJS.M(null,"string-expression","string-expression",-1395337766);Bja=new $CLJS.M(null,"lat-field","lat-field",-830652957);IL=new $CLJS.M("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);JL=new $CLJS.M("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);
KL=new $CLJS.M("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);LL=new $CLJS.M("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);Cja=new $CLJS.r("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);ML=new $CLJS.M("location","country","location/country",1666636202);NL=new $CLJS.M("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);
OL=new $CLJS.M("metabase.mbql.schema","Reference","metabase.mbql.schema/Reference",-770092616);PL=new $CLJS.M("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);Dja=new $CLJS.r("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);QL=new $CLJS.M(null,"unary","unary",-989314568);RL=new $CLJS.M("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
SL=new $CLJS.M("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);TL=new $CLJS.r(null,"ceil","ceil",-184398425,null);Eja=new $CLJS.M(null,"lon-min","lon-min",-787291357);UL=new $CLJS.r(null,"NonBlankString","NonBlankString",-719244809,null);Fja=new $CLJS.M(null,"match","match",1220059550);VL=new $CLJS.r(null,"count-where","count-where",2025939247,null);WL=new $CLJS.M("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
XL=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);Gja=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);YL=new $CLJS.M("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);ZL=new $CLJS.r(null,"sum","sum",1777518341,null);$L=new $CLJS.M("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);aM=new $CLJS.r("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.bM=new $CLJS.M("date","range","date/range",1647265776);cM=new $CLJS.r(null,"between","between",-1523336493,null);dM=new $CLJS.M(null,"clause-form","clause-form",1820463737);Hja=new $CLJS.r("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);eM=new $CLJS.M("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);fM=new $CLJS.r(null,"field","field",338095027,null);Ija=new $CLJS.M(null,"segment-id","segment-id",1810133590);
gM=new $CLJS.M("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);hM=new $CLJS.r(null,"not-null","not-null",313812992,null);iM=new $CLJS.M("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);jM=new $CLJS.M("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);kM=new $CLJS.M("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);Jja=new $CLJS.M(null,"template-tag","template-tag",310841038);
lM=new $CLJS.M(null,"invalid","invalid",412869516);Kja=new $CLJS.r("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);mM=new $CLJS.M("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);nM=new $CLJS.r(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.oM=new $CLJS.M(null,"context","context",-830191113);pM=new $CLJS.r(null,"get-year","get-year",704520253,null);
Lja=new $CLJS.M(null,"format-rows?","format-rows?",992129486);qM=new $CLJS.M("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);rM=new $CLJS.M("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);sM=new $CLJS.M("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.tM=new $CLJS.M(null,"parameters","parameters",-1229919748);Mja=new $CLJS.M(null,"json-download","json-download",-971130133);
$CLJS.uM=new $CLJS.M(null,"card","card",-1430355152);vM=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);wM=new $CLJS.r(null,"!\x3d","!\x3d",-201205829,null);Nja=new $CLJS.r("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);Oja=new $CLJS.r("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);xM=new $CLJS.r(null,"expression","expression",1842843403,null);$CLJS.yM=new $CLJS.M("date","month-year","date/month-year",1948031290);
zM=new $CLJS.M("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);AM=new $CLJS.r(null,"ends-with","ends-with",1183740516,null);BM=new $CLJS.M("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);$CLJS.CM=new $CLJS.M(null,"joins","joins",1033962699);Pja=new $CLJS.M(null,"slug","slug",2029314850);$CLJS.DM=new $CLJS.M(null,"source-field","source-field",933829534);EM=new $CLJS.r(null,"Field","Field",430385967,null);
FM=new $CLJS.r(null,"convert-timezone","convert-timezone",1515728526,null);Qja=new $CLJS.r("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);Rja=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);Sja=new $CLJS.M(null,"items","items",1031954938);GM=new $CLJS.M(null,"datetime-expression","datetime-expression",391782195);HM=new $CLJS.M("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);
IM=new $CLJS.M(null,"more","more",-2058821800);JM=new $CLJS.M(null,"first-clause","first-clause",-20953491);KM=new $CLJS.M("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);LM=new $CLJS.r(null,"OrderComparable","OrderComparable",772072595,null);Tja=new $CLJS.r("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);MM=new $CLJS.r(null,"contains","contains",-1977535957,null);
Uja=new $CLJS.r("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);NM=new $CLJS.r("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);OM=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.PM=new $CLJS.M(null,"widget-type","widget-type",1836256899);QM=new $CLJS.M("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.RM=new $CLJS.M(null,"fingerprint","fingerprint",598613022);
SM=new $CLJS.r(null,"is-null","is-null",-356519403,null);TM=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);UM=new $CLJS.M("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);VM=new $CLJS.M("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);Vja=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);$CLJS.WM=new $CLJS.M(null,"required","required",1807647006);Wja=new $CLJS.M(null,"embedded-csv-download","embedded-csv-download",1858870014);
XM=new $CLJS.M(null,"unnamed-aggregation","unnamed-aggregation",-93854280);Xja=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);Yja=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);YM=new $CLJS.r(null,"CaseClauses","CaseClauses",-1749071354,null);ZM=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);$M=new $CLJS.M("string","contains","string/contains",1602423827);aN=new $CLJS.M("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);
bN=new $CLJS.M("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);Zja=new $CLJS.r("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);cN=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);$ja=new $CLJS.r(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);aka=new $CLJS.r("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);
dN=new $CLJS.M("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);eN=new $CLJS.r("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);fN=new $CLJS.M("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);gN=new $CLJS.M("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);hN=new $CLJS.r(null,"share","share",1051097594,null);
bka=new $CLJS.r("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);cka=new $CLJS.r("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);dka=new $CLJS.r("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);iN=new $CLJS.M("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);eka=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);
jN=new $CLJS.M("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);fka=new $CLJS.r("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.kN=new $CLJS.M(null,"collection","collection",-683361892);lN=new $CLJS.M("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);mN=new $CLJS.M("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);
$CLJS.nN=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);gka=new $CLJS.r("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);oN=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);pN=new $CLJS.r(null,"metric","metric",2049329604,null);qN=new $CLJS.r(null,"concat","concat",-467652465,null);rN=new $CLJS.M(null,"variable","variable",-281346492);$CLJS.sN=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);
hka=new $CLJS.r(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.tN=new $CLJS.M("date","relative","date/relative",25987732);uN=new $CLJS.M("location","city","location/city",-1746973325);ika=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);jka=new $CLJS.r("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);vN=new $CLJS.M("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);wN=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);
xN=new $CLJS.M("number","between","number/between",97700581);kka=new $CLJS.r("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);yN=new $CLJS.M("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);zN=new $CLJS.r(null,"sqrt","sqrt",370479598,null);AN=new $CLJS.M("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);BN=new $CLJS.M("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);
$CLJS.CN=new $CLJS.M(null,"semantic_type","semantic_type",272485089);lka=new $CLJS.M(null,"metric-id","metric-id",-686486942);DN=new $CLJS.r(null,"*","*",345799209,null);EN=new $CLJS.r(null,"+","+",-740910886,null);FN=new $CLJS.r(null,"-","-",-471816912,null);GN=new $CLJS.r(null,"template-tag","template-tag",1951372565,null);HN=new $CLJS.M(null,"allowed-for","allowed-for",122724334);mka=new $CLJS.M(null,"question","question",-1411720117);IN=new $CLJS.r(null,"asc","asc",1997386096,null);
JN=new $CLJS.M("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);nka=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);KN=new $CLJS.M("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);LN=new $CLJS.r(null,"\x3c\x3d","\x3c\x3d",1244895369,null);MN=new $CLJS.r(null,"\x3c","\x3c",993667236,null);NN=new $CLJS.r(null,"\x3e","\x3e",1085014381,null);ON=new $CLJS.r(null,"\x3d","\x3d",-1501502141,null);
PN=new $CLJS.M("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.QN=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);RN=new $CLJS.M("string","ends-with","string/ends-with",302681156);oka=new $CLJS.r("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);SN=new $CLJS.r(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);pka=new $CLJS.r(null,"MetricID","MetricID",-2128635641,null);
TN=new $CLJS.r(null,"and","and",668631710,null);UN=new $CLJS.r(null,"\x3e\x3d","\x3e\x3d",1016916022,null);VN=new $CLJS.r(null,"round","round",-645002441,null);qka=new $CLJS.M(null,"to","to",192099007);$CLJS.WN=new $CLJS.M("date","single","date/single",1554682003);rka=new $CLJS.M(null,"action-id","action-id",-1727958578);ska=new $CLJS.r("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);XN=new $CLJS.r(null,"exp","exp",1378825265,null);
YN=new $CLJS.r(null,"Filter","Filter",-424893332,null);ZN=new $CLJS.r(null,"cum-count","cum-count",-323900016,null);tka=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);$N=new $CLJS.M("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);aO=new $CLJS.M("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);bO=new $CLJS.M("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);
cO=new $CLJS.M(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.dO=new $CLJS.M(null,"source-table","source-table",-225307692);uka=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);vka=new $CLJS.r("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);eO=new $CLJS.r(null,"floor","floor",-772394748,null);wka=new $CLJS.M(null,"middleware","middleware",1462115504);
xka=new $CLJS.r("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);fO=new $CLJS.M(null,"requires-features","requires-features",-101116256);gO=new $CLJS.M("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);hO=new $CLJS.M(null,"clause-name","clause-name",-996419059);iO=new $CLJS.M("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);jO=new $CLJS.r(null,"now","now",-9994004,null);
yka=new $CLJS.r("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);kO=new $CLJS.r(null,"not","not",1044554643,null);lO=new $CLJS.r(null,"avg","avg",1837937727,null);mO=new $CLJS.r(null,"sum-where","sum-where",-519087341,null);nO=new $CLJS.M(null,"max-results","max-results",-32858165);zka=new $CLJS.r("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);oO=new $CLJS.r(null,"case","case",-1510733573,null);
pO=new $CLJS.r(null,"distinct","distinct",-148347594,null);qO=new $CLJS.r(null,"get-second","get-second",-425414791,null);rO=new $CLJS.M("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.sO=new $CLJS.M(null,"join-alias","join-alias",1454206794);tO=new $CLJS.r(null,"is-empty","is-empty",600228619,null);Aka=new $CLJS.r("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
uO=new $CLJS.r(null,"relative-datetime","relative-datetime",-1369266491,null);vO=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);Bka=new $CLJS.M(null,"original","original",-445386197);wO=new $CLJS.M("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);xO=new $CLJS.r(null,"abs","abs",1394505050,null);yO=new $CLJS.r(null,"datetime-diff","datetime-diff",-1521323614,null);Cka=new $CLJS.r("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);
zO=new $CLJS.M("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);Dka=new $CLJS.r(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.AO=new $CLJS.M(null,"date","date",-1463434462);BO=new $CLJS.M(null,"second-clause","second-clause",-461435645);Eka=new $CLJS.r("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);CO=new $CLJS.r(null,"rtrim","rtrim",979195078,null);
DO=new $CLJS.M("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);EO=new $CLJS.M("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);FO=new $CLJS.r("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);GO=new $CLJS.M("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);HO=new $CLJS.r(null,"CaseOptions","CaseOptions",1989286806,null);IO=new $CLJS.r(null,"or","or",1876275696,null);
Fka=new $CLJS.r("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);Gka=new $CLJS.M(null,"constraints","constraints",422775616);Hka=new $CLJS.r("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);JO=new $CLJS.M("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);Ika=new $CLJS.M(null,"csv-download","csv-download",2141432084);KO=new $CLJS.M("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);
LO=new $CLJS.r(null,"datetime-add","datetime-add",1850134938,null);Jka=new $CLJS.r("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);Kka=new $CLJS.r("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);MO=new $CLJS.r("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);NO=new $CLJS.r("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);
OO=new $CLJS.r("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);Lka=new $CLJS.r("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);PO=new $CLJS.r(null,"get-quarter","get-quarter",-1326657176,null);Mka=new $CLJS.r("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);QO=new $CLJS.r("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);Nka=new $CLJS.r("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);
Oka=new $CLJS.r("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);RO=new $CLJS.r(null,"temporal-extract","temporal-extract",-872749364,null);Pka=new $CLJS.r("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);Qka=new $CLJS.r("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);Rka=new $CLJS.M(null,"display_name","display_name",-1494335013);$CLJS.SO=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);
$CLJS.TO=new $CLJS.M(null,"database","database",1849087575);UO=new $CLJS.r(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);VO=new $CLJS.r(null,"get-day-of-week","get-day-of-week",-644295017,null);Ska=new $CLJS.r("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);WO=new $CLJS.M("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);
XO=new $CLJS.M("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.YO=new $CLJS.M(null,"expressions","expressions",255689909);ZO=new $CLJS.M("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);Tka=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);$O=new $CLJS.M("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);
Uka=new $CLJS.r("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);aP=new $CLJS.M("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);bP=new $CLJS.M("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);cP=new $CLJS.r(null,"absolute-datetime","absolute-datetime",1080191062,null);Vka=new $CLJS.r(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);
dP=new $CLJS.r(null,"get-day","get-day",1768100384,null);eP=new $CLJS.M("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);fP=new $CLJS.M("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);gP=new $CLJS.r(null,"not-empty","not-empty",2029453590,null);$CLJS.hP=new $CLJS.M(null,"native","native",-613060878);Wka=new $CLJS.r("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);iP=new $CLJS.M(null,"page","page",849072397);
jP=new $CLJS.r(null,"length","length",-2065447907,null);Xka=new $CLJS.M(null,"dashboard","dashboard",-631747508);kP=new $CLJS.r(null,"get-week","get-week",752472178,null);lP=new $CLJS.r(null,"get-month","get-month",1271156796,null);mP=new $CLJS.r(null,"dimension","dimension",-2111181571,null);nP=new $CLJS.M(null,"boolean-expression","boolean-expression",-394924008);oP=new $CLJS.M("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);
pP=new $CLJS.r(null,"StringFilterOptions","StringFilterOptions",-847398229,null);qP=new $CLJS.M("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);rP=new $CLJS.r("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);Yka=new $CLJS.r("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);sP=new $CLJS.M("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);tP=new $CLJS.r(null,"substring","substring",-1513569493,null);
Zka=new $CLJS.r("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);uP=new $CLJS.M(null,"internal","internal",-854870097);$ka=new $CLJS.r("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);vP=new $CLJS.M("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);wP=new $CLJS.M("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);
xP=new $CLJS.M("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);ala=new $CLJS.r("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);yP=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);zP=new $CLJS.r(null,"ltrim","ltrim",-1000166486,null);bla=new $CLJS.r("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);
cla=new $CLJS.r("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);AP=new $CLJS.M("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);BP=new $CLJS.M("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);CP=new $CLJS.r("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);
dla=new $CLJS.r("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);ela=new $CLJS.r("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);DP=new $CLJS.r(null,"desc","desc",-560950005,null);$CLJS.EP=new $CLJS.M(null,"template-tags","template-tags",1853115685);fla=new $CLJS.M(null,"public-question","public-question",629369976);gla=new $CLJS.r("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
hla=new $CLJS.M(null,"binary","binary",-1802232288);FP=new $CLJS.r(null,"time-interval","time-interval",-1949813754,null);ila=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);$CLJS.GP=new $CLJS.M(null,"source-query","source-query",198004422);HP=new $CLJS.M("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);jla=new $CLJS.r(null,"SegmentID","SegmentID",-1773652704,null);
kla=new $CLJS.r("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);IP=new $CLJS.M("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);JP=new $CLJS.M("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);lla=new $CLJS.M(null,"executed-by","executed-by",-739811161);mla=new $CLJS.r("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
nla=new $CLJS.r("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);KP=new $CLJS.M(null,"amount","amount",364489504);LP=new $CLJS.r(null,"percentile","percentile",1039342775,null);MP=new $CLJS.M("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);NP=new $CLJS.r(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);OP=new $CLJS.M("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
PP=new $CLJS.r(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);QP=new $CLJS.r(null,"trim","trim",-1880116002,null);ola=new $CLJS.r(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);RP=new $CLJS.r(null,"coalesce","coalesce",-1999813740,null);SP=new $CLJS.M("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);pla=new $CLJS.M("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
TP=new $CLJS.M("string","\x3d","string/\x3d",983744235);qla=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);rla=new $CLJS.r("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);sla=new $CLJS.r("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);UP=new $CLJS.r(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);VP=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);
WP=new $CLJS.r(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);tla=new $CLJS.M(null,"lat-min","lat-min",1630784161);XP=new $CLJS.r(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);YP=new $CLJS.r(null,"inside","inside",-681932758,null);ula=new $CLJS.r("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);ZP=new $CLJS.M("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
vla=new $CLJS.r("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);$P=new $CLJS.r(null,"starts-with","starts-with",2007034536,null);wla=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);xla=new $CLJS.r("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.aQ=new $CLJS.M(null,"card-id","card-id",-1770060179);bQ=new $CLJS.M(null,"variadic","variadic",882626057);
cQ=new $CLJS.r(null,"upper","upper",1886775433,null);dQ=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);eQ=new $CLJS.M("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);fQ=new $CLJS.r(null,"optional","optional",-600484260,null);yla=new $CLJS.r("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);gQ=new $CLJS.M("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);
hQ=new $CLJS.M(null,"sugar","sugar",-73788488);zla=new $CLJS.M(null,"lat-max","lat-max",841568226);iQ=new $CLJS.r(null,"power","power",702679448,null);jQ=new $CLJS.r("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);kQ=new $CLJS.M("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);lQ=new $CLJS.M("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);
mQ=new $CLJS.M("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);nQ=new $CLJS.r(null,"median","median",-2084869638,null);oQ=new $CLJS.M("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);pQ=new $CLJS.r(null,"cum-sum","cum-sum",-589533420,null);Ala=new $CLJS.r("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);qQ=new $CLJS.M(null,"y","y",-1757859776);rQ=new $CLJS.r(null,"aggregation","aggregation",-1056959073,null);
$CLJS.sQ=new $CLJS.M(null,"binning","binning",1709835866);tQ=new $CLJS.r(null,"TimezoneId","TimezoneId",-1406242397,null);uQ=new $CLJS.M("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);vQ=new $CLJS.M("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);wQ=new $CLJS.M("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);xQ=new $CLJS.r(null,"Reference","Reference",2024574086,null);yQ=new $CLJS.M(null,"b","b",1482224470);
zQ=new $CLJS.M(null,"a","a",-2123407586);AQ=new $CLJS.M("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);BQ=new $CLJS.r(null,"replace","replace",853943757,null);CQ=new $CLJS.M("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);Bla=new $CLJS.r("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);DQ=new $CLJS.M("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);
EQ=new $CLJS.M("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);FQ=new $CLJS.M("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);GQ=new $CLJS.M("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);HQ=new $CLJS.r(null,"segment","segment",675610331,null);Cla=new $CLJS.r("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);IQ=new $CLJS.M("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);
JQ=new $CLJS.r("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);KQ=new $CLJS.M("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);LQ=new $CLJS.M("string","starts-with","string/starts-with",1266861170);Dla=new $CLJS.r(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.MQ=new $CLJS.M(null,"order-by","order-by",1527318070);NQ=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);
Ela=new $CLJS.r("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);Fla=new $CLJS.M("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.OQ=new $CLJS.M(null,"condition","condition",1668437652);PQ=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);Gla=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);QQ=new $CLJS.r("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);
Hla=new $CLJS.M(null,"card-name","card-name",-2035606807);RQ=new $CLJS.M("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);Ila=new $CLJS.r("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);Jla=new $CLJS.r("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);SQ=new $CLJS.r(null,"log","log",45015523,null);Kla=new $CLJS.r("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);
TQ=new $CLJS.r(null,"time","time",-1268547887,null);$CLJS.UQ=new $CLJS.M(null,"database_type","database_type",-54700895);VQ=new $CLJS.r(null,"EqualityComparable","EqualityComparable",-2076258858,null);WQ=new $CLJS.r(null,"get-minute","get-minute",-1186349426,null);$CLJS.XQ=new $CLJS.M(null,"clause","clause",1479668060);$CLJS.YQ=new $CLJS.M(null,"expression-name","expression-name",-1798450709);$CLJS.ZQ=new $CLJS.M("date","all-options","date/all-options",549325958);
Lla=new $CLJS.r("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);Mla=new $CLJS.M(null,"embedded-json-download","embedded-json-download",-1980927033);$Q=new $CLJS.M("location","state","location/state",-114378652);aR=new $CLJS.r(null,"datetime-subtract","datetime-subtract",1915839224,null);Nla=new $CLJS.M(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);Ola=new $CLJS.M(null,"lon-field","lon-field",517872067);
bR=new $CLJS.M("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);cR=new $CLJS.r(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);dR=new $CLJS.M(null,"numeric","numeric",-1495594714);eR=new $CLJS.r(null,"variable","variable",1359185035,null);fR=new $CLJS.r(null,"lower","lower",-1534114948,null);Pla=new $CLJS.M(null,"embedded-xlsx-download","embedded-xlsx-download",-1105087722);
gR=new $CLJS.M("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);hR=new $CLJS.M("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.iR=new $CLJS.M(null,"limit","limit",-1355822363);jR=new $CLJS.M("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);kR=new $CLJS.r(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);Qla=new $CLJS.M(null,"pulse","pulse",-244494476);var lR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.sD],null),mR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.BD],null),nR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.oD],null),oR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.kD],null),pR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.wD],null),qR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.gK],null),Rla=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.VJ],null),Sla=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.PJ],null),rR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,
$CLJS.JH],null),sR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.NH],null),tR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.EH],null),uR=new $CLJS.Rg(null,new $CLJS.h(null,12,[$CLJS.bi,null,$CLJS.Lk,null,$CLJS.Ti,null,$CLJS.Oh,null,$CLJS.oi,null,$CLJS.Zi,null,$CLJS.Cj,null,$CLJS.gj,null,$CLJS.Wh,null,$CLJS.fj,null,$CLJS.ci,null,$CLJS.ji,null],null),null),vR=new $CLJS.Rg(null,new $CLJS.h(null,7,[$CLJS.Kk,null,$CLJS.VF,null,$CLJS.Oh,null,$CLJS.Ck,null,$CLJS.YF,null,$CLJS.Pi,null,$CLJS.Gk,null],null),null),
wR=$CLJS.Ws.g(uR,vR),Tla=$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.ts,"date bucketing unit"],null)],null),uR),Ula=$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.ts,"time bucketing unit"],null)],null),vR),xR=$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.ts,"datetime bucketing unit"],null)],null),wR),yR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.iI],null),Vla=new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,
1,[$CLJS.ts,"temporal extract unit"],null),$CLJS.ZF,$CLJS.ji,$CLJS.Cj,$CLJS.ZH,$CLJS.mI,$CLJS.nI,$CLJS.gj,$CLJS.oi,$CLJS.Ck,$CLJS.Pi,$CLJS.WF],null),Wla=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.ts,"datetime-diff unit"],null),$CLJS.VF,$CLJS.Gk,$CLJS.Kk,$CLJS.Lk,$CLJS.Ti,$CLJS.Zi,$CLJS.bi,$CLJS.Wh],null),zR=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.ts,"temporal-extract week extraction mode"],null),$CLJS.TH,$CLJS.$H,$CLJS.dI],null),AR=new $CLJS.P(null,
10,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.ts,"relative-datetime unit"],null),$CLJS.Oh,$CLJS.Gk,$CLJS.Kk,$CLJS.Lk,$CLJS.Ti,$CLJS.Zi,$CLJS.bi,$CLJS.Wh],null),HR,Yla,PR,QR,RR,SR,TR,UR,VR,IT,$la,JT,ama,bma,KT,cma,dma,ema;$CLJS.Y(SL,bL($CLJS.$A,$CLJS.H(["n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,$CLJS.Qu],null),$CLJS.pr],null),"unit",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,AR],null)])));var BR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,SL],null);
$CLJS.Y(wO,bL($CLJS.ZA,$CLJS.H(["n",$CLJS.pr,"unit",AR])));var Xla=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,wO],null);
$CLJS.Y(mM,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"valid :absolute-datetime clause",$CLJS.wi,function(a){if($CLJS.Ra(aL($CLJS.XH,a)))a=lM;else{a=$CLJS.dd(a);var b=$CLJS.XK(rR);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.AO:$CLJS.aI}return a}],null),new $CLJS.P(null,2,5,$CLJS.Q,[lM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ts,"not an :absolute-datetime clause"],null),$CLJS.Pe(!1)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AO,bL($CLJS.XH,
$CLJS.H(["date",rR,"unit",Tla]))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aI,bL($CLJS.XH,$CLJS.H(["datetime",sR,"unit",xR]))],null)],null));var CR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,mM],null);$CLJS.Y(BM,bL($CLJS.Fx,$CLJS.H(["time",tR,"unit",Ula])));var DR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,BM],null),ER=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.dr,new $CLJS.h(null,1,[$CLJS.ts,"date or datetime literal"],null),CR,sR,rR],null);
$CLJS.Y(mL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dr,new $CLJS.h(null,1,[$CLJS.ts,"time literal"],null),DR,tR],null));$CLJS.Y(bR,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dr,new $CLJS.h(null,1,[$CLJS.ts,"temporal literal"],null),ER,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,mL],null)],null));var FR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,bR],null);
$CLJS.Y(pla,cL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof CR?new $CLJS.Cc(function(){return CR},$CLJS.ld(Qja,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,uP,$CLJS.Si,$CLJS.Pj,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),cP,"metabase/mbql/schema.cljc",69,$CLJS.XH,1,!0,168,168,$CLJS.Gc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(CR)?CR.H:null])):null));return $CLJS.n(a)?a:cP}(),CR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof BR?new $CLJS.Cc(function(){return BR},$CLJS.ld(Lla,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,
$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uO,"metabase/mbql/schema.cljc",29,$CLJS.$A,1,131,131,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.$A,$CLJS.Ij,$CLJS.U($CLJS.oj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,$CLJS.Qu],null),$CLJS.pr],null)),$CLJS.di,$CLJS.U($CLJS.oj,$CLJS.U(fQ,SN))],null),$CLJS.Gc,"Schema for a valid relative-datetime clause.",
$CLJS.n(BR)?BR.H:null])):null));return $CLJS.n(a)?a:uO}(),BR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof DR?new $CLJS.Cc(function(){return DR},$CLJS.ld(Oka,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,uP,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),TQ,"metabase/mbql/schema.cljc",27,$CLJS.Fx,1,!0,175,175,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Fx,$CLJS.Fx,$CLJS.U($CLJS.oj,ola),$CLJS.di,$CLJS.U($CLJS.oj,hka)],null),$CLJS.Gc,"Schema for a valid time clause.",$CLJS.n(DR)?DR.H:null])):null));return $CLJS.n(a)?a:TQ}(),DR],null)])));
$CLJS.Y(RQ,bL($CLJS.dj,$CLJS.H(["value",$CLJS.ur,"type-info",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UQ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,lR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dC,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,mR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CN,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.cr,nR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.di,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,xR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,lR],null)],null)],null)],null)])));var GR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,RQ],null);$CLJS.Y(BN,bL($CLJS.qA,$CLJS.H(["expression-name",lR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,$CLJS.zj],null)])));
HR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,BN],null);
Yla=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ts,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.O(b);b=$CLJS.K.g(c,$CLJS.DD);c=$CLJS.K.g(c,$CLJS.lD);return $CLJS.E.g(b,$CLJS.lD)?c:!0}],null)],null)}(function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ts,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.O(b);b=$CLJS.K.g(c,$CLJS.DD);c=$CLJS.K.g(c,$CLJS.CD);return $CLJS.E.g(b,$CLJS.CD)?c:!0}],null)],null)}(new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.ts,"binning options"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DD,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.ts,"binning strategy"],null),$CLJS.lD,$CLJS.CD,$CLJS.Oh],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),oR],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.CD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,$CLJS.$k,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ts,"bin width must be \x3e\x3d 0."],null),$CLJS.Oe($CLJS.ul)],null)],null)],null)],null)));$CLJS.IR=function IR(a){switch(arguments.length){case 1:return IR.h(arguments[0]);case 2:return IR.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.IR.h=function(a){var b=$CLJS.O(a);a=$CLJS.K.g(b,$CLJS.tA);b=$CLJS.K.g(b,$CLJS.rF);return $CLJS.IR.g(a,b)};$CLJS.IR.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.Dz(a,$CLJS.Dk)?uR:$CLJS.Dz(a,$CLJS.Mk)?vR:$CLJS.Dz(a,$CLJS.Hk)?wR:null:null;return $CLJS.n(a)?$CLJS.Dd(a,b):!0};$CLJS.IR.A=2;
$CLJS.Y(gQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.ts,"field options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tA,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,mR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DM,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,qR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rF,new $CLJS.h(null,
1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,xR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sO,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,lR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sQ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,Yla],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ts,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.IR],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ts,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.Oe($CLJS.DD)],null)],null));
$CLJS.Y(IQ,function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ts,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);b=$CLJS.O(b);b=$CLJS.K.g(b,$CLJS.tA);return"string"===typeof c?b:!0}],null)],null)}(bL($CLJS.lF,$CLJS.H(["id-or-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,qR,lR],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,gQ],null)],null)]))));var JR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,IQ],null);
$CLJS.Y(JO,cL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof HR?new $CLJS.Cc(function(){return HR},$CLJS.ld(NM,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.YO,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),xM,"metabase/mbql/schema.cljc",60,$CLJS.qA,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.qA,$CLJS.YQ,$CLJS.U($CLJS.oj,UL),$CLJS.Mi,$CLJS.U($CLJS.oj,$CLJS.U(fQ,$CLJS.zj))],null),$CLJS.Gc,"Schema for a valid expression clause.",$CLJS.n(HR)?HR.H:null])):null));return $CLJS.n(a)?a:xM}(),HR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof JR?new $CLJS.Cc(function(){return JR},
$CLJS.ld(eN,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.dq,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],["0.39.0",$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fM,"metabase/mbql/schema.cljc",51,$CLJS.lF,1,382,382,$CLJS.Gc,"Schema for a `:field` clause.",$CLJS.n(JR)?JR.H:null])):null));return $CLJS.n(a)?a:fM}(),JR],null)])));
$CLJS.KR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,JO],null);$CLJS.Y(hL,bL($CLJS.aF,$CLJS.H(["aggregation-clause-index",$CLJS.pr,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,$CLJS.zj],null)])));var LR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,hL],null);
$CLJS.Y(OL,cL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof LR?new $CLJS.Cc(function(){return LR},$CLJS.ld(mla,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),rQ,"metabase/mbql/schema.cljc",23,$CLJS.aF,1,418,418,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.aF,Nla,$CLJS.U($CLJS.oj,$CLJS.pr),$CLJS.Mi,$CLJS.U($CLJS.oj,$CLJS.U(fQ,$CLJS.zj))],null),$CLJS.Gc,"Schema for a valid aggregation clause.",$CLJS.n(LR)?LR.H:null])):null));return $CLJS.n(a)?a:rQ}(),LR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof HR?new $CLJS.Cc(function(){return HR},
$CLJS.ld(NM,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.YO,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xM,"metabase/mbql/schema.cljc",60,$CLJS.qA,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.qA,$CLJS.YQ,$CLJS.U($CLJS.oj,UL),
$CLJS.Mi,$CLJS.U($CLJS.oj,$CLJS.U(fQ,$CLJS.zj))],null),$CLJS.Gc,"Schema for a valid expression clause.",$CLJS.n(HR)?HR.H:null])):null));return $CLJS.n(a)?a:xM}(),HR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof JR?new $CLJS.Cc(function(){return JR},$CLJS.ld(eN,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.dq,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,
$CLJS.ei,$CLJS.Bj,$CLJS.Fj],["0.39.0",$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fM,"metabase/mbql/schema.cljc",51,$CLJS.lF,1,382,382,$CLJS.Gc,"Schema for a `:field` clause.",$CLJS.n(JR)?JR.H:null])):null));return $CLJS.n(a)?a:fM}(),JR],null)])));
var MR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,OL],null),NR=new $CLJS.Rg(null,new $CLJS.h(null,11,[$CLJS.js,null,$CLJS.DF,null,$CLJS.DE,null,$CLJS.JF,null,$CLJS.KE,null,$CLJS.oF,null,$CLJS.UE,null,$CLJS.ps,null,$CLJS.BF,null,$CLJS.nF,null,$CLJS.KF,null],null),null),OR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,LL],null);
$CLJS.Y(oL,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,1,[$CLJS.wi,function(a){return"string"===typeof a?$CLJS.uj:$CLJS.n(aL(NR,a))?HL:$CLJS.n(aL($CLJS.dj,a))?$CLJS.dj:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uj,$CLJS.uj],null),new $CLJS.P(null,2,5,$CLJS.Q,[HL,OR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,GR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.KR],null)],null));PR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,oL],null);
QR=new $CLJS.Rg(null,new $CLJS.h(null,26,[$CLJS.GE,null,$CLJS.lI,null,$CLJS.Kq,null,$CLJS.cB,null,$CLJS.gI,null,$CLJS.ZE,null,$CLJS.Zr,null,$CLJS.CE,null,$CLJS.jI,null,$CLJS.aB,null,$CLJS.oF,null,$CLJS.GF,null,$CLJS.hI,null,$CLJS.qF,null,$CLJS.IE,null,$CLJS.VH,null,$CLJS.Jq,null,$CLJS.LE,null,$CLJS.UE,null,$CLJS.PH,null,$CLJS.Pw,null,$CLJS.WH,null,$CLJS.eI,null,$CLJS.YA,null,$CLJS.IF,null,$CLJS.jF,null],null),null);
RR=new $CLJS.Rg(null,new $CLJS.h(null,9,[$CLJS.Hq,null,$CLJS.Bq,null,$CLJS.dr,null,$CLJS.fr,null,$CLJS.Dq,null,$CLJS.CF,null,$CLJS.kr,null,$CLJS.Jj,null,$CLJS.Fq,null],null),null);SR=new $CLJS.Rg(null,new $CLJS.h(null,17,[$CLJS.ri,null,$CLJS.AF,null,$CLJS.uF,null,$CLJS.mF,null,$CLJS.FF,null,$CLJS.HF,null,$CLJS.QE,null,$CLJS.XE,null,$CLJS.tL,null,$CLJS.RE,null,$CLJS.yF,null,$CLJS.sF,null,$CLJS.Aj,null,$CLJS.Rw,null,$CLJS.fF,null,$CLJS.gF,null,$CLJS.xF,null],null),null);
TR=new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.bI,null,$CLJS.RH,null,$CLJS.eB,null,$CLJS.Jq,null,$CLJS.bB,null],null),null);UR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,ZP],null);VR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,zM],null);$CLJS.WR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,AP],null);$CLJS.YR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,eQ],null);
$CLJS.Y(lQ,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"numeric expression argument",$CLJS.wi,function(a){return"number"===typeof a?$CLJS.Ps:$CLJS.n(aL(QR,a))?cO:$CLJS.n(aL(SR,a))?$CLJS.aF:$CLJS.n(aL($CLJS.dj,a))?$CLJS.dj:$CLJS.lF}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ps,$CLJS.$k],null),new $CLJS.P(null,2,5,$CLJS.Q,[cO,UR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aF,$CLJS.YR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,GR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lF,
$CLJS.KR],null)],null));var ZR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,lQ],null);
$CLJS.Y(vP,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"datetime expression argument",$CLJS.wi,function(a){return $CLJS.n(aL(SR,a))?$CLJS.aF:$CLJS.n(aL($CLJS.dj,a))?$CLJS.dj:$CLJS.n(aL(TR,a))?GM:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aF,$CLJS.YR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,GR],null),new $CLJS.P(null,2,5,$CLJS.Q,[GM,$CLJS.WR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,ER,$CLJS.KR],null)],null)],null));
var $R=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,vP],null);
$CLJS.Y(ZO,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"expression argument",$CLJS.wi,function(a){return"number"===typeof a?$CLJS.Ps:$CLJS.yd(a)?$CLJS.tr:$CLJS.n(aL(RR,a))?nP:$CLJS.n(aL(QR,a))?cO:$CLJS.n(aL(TR,a))?GM:"string"===typeof a?$CLJS.uj:$CLJS.n(aL(NR,a))?HL:$CLJS.n(aL($CLJS.dj,a))?$CLJS.dj:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ps,$CLJS.$k],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tr,$CLJS.tr],null),new $CLJS.P(null,2,5,$CLJS.Q,[nP,VR],null),new $CLJS.P(null,
2,5,$CLJS.Q,[cO,UR],null),new $CLJS.P(null,2,5,$CLJS.Q,[GM,$CLJS.WR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uj,$CLJS.uj],null),new $CLJS.P(null,2,5,$CLJS.Q,[HL,OR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,GR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.KR],null)],null));var aS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,ZO],null);$CLJS.Y(XO,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dr,new $CLJS.h(null,1,[$CLJS.ts,"numeric expression arg or interval"],null),Xla,ZR],null));
var bS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,XO],null);$CLJS.Y(zL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"int greater than zero or numeric expression",$CLJS.wi,function(a){return"number"===typeof a?$CLJS.Ps:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ps,oR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,UR],null)],null));var Zla=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,zL],null);
$CLJS.Y(HM,bL($CLJS.oF,$CLJS.H(["a",aS,"b",aS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,aS],null)])));var cS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,HM],null);$CLJS.Y(lL,bL($CLJS.DE,$CLJS.H(["s",PR,"start",Zla,"length",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,ZR],null)])));var dS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,lL],null);$CLJS.Y(oP,bL($CLJS.Pw,$CLJS.H(["s",PR])));var eS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,oP],null);$CLJS.Y(NL,bL($CLJS.BF,$CLJS.H(["s",PR])));
var fS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,NL],null);$CLJS.Y(RL,bL($CLJS.KF,$CLJS.H(["s",PR])));var gS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,RL],null);$CLJS.Y(jM,bL($CLJS.DF,$CLJS.H(["s",PR])));var hS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,jM],null);$CLJS.Y(VM,bL($CLJS.js,$CLJS.H(["s",PR])));var iS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,VM],null);$CLJS.Y(EL,bL($CLJS.ps,$CLJS.H(["s",PR])));var jS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,EL],null);
$CLJS.Y(kQ,bL($CLJS.JF,$CLJS.H(["s",PR,"match",$CLJS.uj,"replacement",$CLJS.uj])));var kS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,kQ],null);$CLJS.Y(gR,bL($CLJS.KE,$CLJS.H(["a",PR,"b",PR,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,PR],null)])));var lS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,gR],null);$CLJS.Y(KN,bL($CLJS.nF,$CLJS.H(["s",PR,"pattern",$CLJS.uj])));var mS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,KN],null);
$CLJS.Y(GQ,bL($CLJS.Jq,$CLJS.H(["x",bS,"y",bS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,bS],null)])));var nS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,GQ],null);$CLJS.Y(EQ,bL($CLJS.Zr,$CLJS.H(["x",ZR,"y",bS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,bS],null)])));var oS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,EQ],null);$CLJS.Y(DQ,bL($CLJS.GF,$CLJS.H(["x",ZR,"y",ZR,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,ZR],null)])));var qS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,DQ],null);
$CLJS.Y(FQ,bL($CLJS.Kq,$CLJS.H(["x",ZR,"y",ZR,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,ZR],null)])));var rS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,FQ],null);$CLJS.Y(WO,bL($CLJS.jF,$CLJS.H(["x",ZR])));var sS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,WO],null);$CLJS.Y(OP,bL($CLJS.GE,$CLJS.H(["x",ZR])));var tS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,OP],null);$CLJS.Y(jR,bL($CLJS.qF,$CLJS.H(["x",ZR])));var uS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,jR],null);$CLJS.Y(aO,bL($CLJS.LE,$CLJS.H(["x",ZR])));
var vS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,aO],null);$CLJS.Y(gO,bL($CLJS.IE,$CLJS.H(["x",ZR,"y",ZR])));var wS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,gO],null);$CLJS.Y(DO,bL($CLJS.ZE,$CLJS.H(["x",ZR])));var xS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,DO],null);$CLJS.Y(AL,bL($CLJS.CE,$CLJS.H(["x",ZR])));var yS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,AL],null);$CLJS.Y(bP,bL($CLJS.IF,$CLJS.H(["x",ZR])));var zS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,bP],null);
$CLJS.Y($L,bL($CLJS.YA,$CLJS.H(["datetime-x",$R,"datetime-y",$R,"unit",Wla])));var AS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$L],null);$CLJS.Y(rM,bL($CLJS.cB,$CLJS.H(["datetime",$R,"unit",Vla,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,zR],null)])));var BS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,rM],null);$CLJS.Y(iO,bL($CLJS.WH,$CLJS.H(["date",$R])));var CS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,iO],null);$CLJS.Y(KO,bL($CLJS.lI,$CLJS.H(["date",$R])));
var DS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,KO],null);$CLJS.Y(nL,bL($CLJS.PH,$CLJS.H(["date",$R])));var ES=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,nL],null);$CLJS.Y(hR,bL($CLJS.aB,$CLJS.H(["date",$R,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,zR],null)])));var FS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,hR],null);$CLJS.Y(vN,bL($CLJS.eI,$CLJS.H(["date",$R])));var GS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,vN],null);$CLJS.Y(EO,bL($CLJS.hI,$CLJS.H(["date",$R])));
var HS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,EO],null);$CLJS.Y(WL,bL($CLJS.jI,$CLJS.H(["datetime",$R])));var IS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,WL],null);$CLJS.Y(AN,bL($CLJS.gI,$CLJS.H(["datetime",$R])));var JS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,AN],null);$CLJS.Y(PL,bL($CLJS.VH,$CLJS.H(["datetime",$R])));var KS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,PL],null);$CLJS.Y(fN,bL($CLJS.RH,$CLJS.H(["datetime",$R,"to",yR,"from",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,yR],null)])));
var LS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,fN],null),MS=new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.ts,"datetime arithmetic unit"],null),$CLJS.YF,$CLJS.VF,$CLJS.Gk,$CLJS.Kk,$CLJS.Lk,$CLJS.Ti,$CLJS.Zi,$CLJS.bi,$CLJS.Wh],null);$CLJS.Y(kL,bL($CLJS.bB,$CLJS.H(["datetime",$R,"amount",ZR,"unit",MS])));var NS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,kL],null);
$CLJS.Y(IL,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return bL(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)}($CLJS.bI));var OS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,IL],null);$CLJS.Y(jL,bL($CLJS.eB,$CLJS.H(["datetime",$R,"amount",ZR,"unit",MS])));var PS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,jL],null);
$CLJS.Y(AP,cL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof nS?new $CLJS.Cc(function(){return nS},$CLJS.ld(QO,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.YO,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),EN,"metabase/mbql/schema.cljc",51,$CLJS.Jq,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Jq,$CLJS.AD,$CLJS.U($CLJS.oj,UO),qQ,$CLJS.U($CLJS.oj,UO),IM,$CLJS.U($CLJS.oj,$CLJS.U($CLJS.ui,UO))],null),$CLJS.Gc,"Schema for a valid + clause.",$CLJS.n(nS)?nS.H:null])):null));return $CLJS.n(a)?a:EN}(),nS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof NS?new $CLJS.Cc(function(){return NS},
$CLJS.ld(yka,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[ZM,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LO,"metabase/mbql/schema.cljc",67,$CLJS.bB,1,703,703,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.bB,$CLJS.aI,$CLJS.U($CLJS.oj,cR),KP,$CLJS.U($CLJS.oj,
PP),$CLJS.di,$CLJS.U($CLJS.oj,XP)],null),$CLJS.Gc,"Schema for a valid datetime-add clause.",$CLJS.n(NS)?NS.H:null])):null));return $CLJS.n(a)?a:LO}(),NS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof PS?new $CLJS.Cc(function(){return PS},$CLJS.ld(rja,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,
$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[ZM,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aR,"metabase/mbql/schema.cljc",72,$CLJS.eB,1,710,710,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.eB,$CLJS.aI,$CLJS.U($CLJS.oj,cR),KP,$CLJS.U($CLJS.oj,PP),$CLJS.di,$CLJS.U($CLJS.oj,XP)],null),$CLJS.Gc,"Schema for a valid datetime-subtract clause.",$CLJS.n(PS)?PS.H:null])):null));
return $CLJS.n(a)?a:aR}(),PS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof LS?new $CLJS.Cc(function(){return LS},$CLJS.ld(vla,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.RH,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FM,"metabase/mbql/schema.cljc",71,$CLJS.RH,1,695,695,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.RH,$CLJS.aI,$CLJS.U($CLJS.oj,cR),qka,$CLJS.U($CLJS.oj,tQ),vja,$CLJS.U($CLJS.oj,$CLJS.U(fQ,tQ))],null),$CLJS.Gc,"Schema for a valid convert-timezone clause.",$CLJS.n(LS)?LS.H:null])):null));return $CLJS.n(a)?a:FM}(),LS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&
"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof OS?new $CLJS.Cc(function(){return OS},$CLJS.ld(Yka,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bI,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jO,"metabase/mbql/schema.cljc",
45,$CLJS.bI,1,708,708,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.bI],null),$CLJS.Gc,"Schema for a valid now clause.",$CLJS.n(OS)?OS.H:null])):null));return $CLJS.n(a)?a:jO}(),OS],null)])));$CLJS.QS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,GO],null);$CLJS.Y(pL,bL($CLJS.kr,$CLJS.H(["first-clause",$CLJS.QS,"second-clause",$CLJS.QS,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,$CLJS.QS],null)])));var RS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,pL],null);
$CLJS.Y(bN,bL($CLJS.dr,$CLJS.H(["first-clause",$CLJS.QS,"second-clause",$CLJS.QS,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,$CLJS.QS],null)])));var SS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,bN],null);$CLJS.Y(KL,bL($CLJS.fr,$CLJS.H(["clause",$CLJS.QS])));
var TS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,KL],null),US=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,3,[$CLJS.ts,":field or :expression reference or :relative-datetime",$CLJS.Es,$CLJS.Pe(":field or :expression reference or :relative-datetime"),$CLJS.wi,function(a){return $CLJS.n(aL($CLJS.$A,a))?$CLJS.$A:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$A,BR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.KR],null)],null);
$CLJS.Y(xP,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cr,new $CLJS.h(null,1,[$CLJS.ts,"equality comparable"],null),new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.dr,$CLJS.tr,$CLJS.$k,$CLJS.uj,FR,US,aS,GR],null)],null));var VS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,xP],null);
$CLJS.Y(PN,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"order comparable",$CLJS.wi,function(a){return $CLJS.n(aL($CLJS.dj,a))?$CLJS.dj:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,GR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.dr,$CLJS.$k,$CLJS.uj,FR,aS,US],null)],null)],null));var WS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,PN],null);
$CLJS.Y(wQ,bL($CLJS.Jj,$CLJS.H(["field",VS,"value-or-field",VS,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,VS],null)])));var XS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,wQ],null);$CLJS.Y(AQ,bL($CLJS.CF,$CLJS.H(["field",VS,"value-or-field",VS,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,VS],null)])));var YS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,AQ],null);$CLJS.Y(vQ,bL($CLJS.Fq,$CLJS.H(["field",WS,"value-or-field",WS])));
var ZS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,vQ],null);$CLJS.Y(uQ,bL($CLJS.Bq,$CLJS.H(["field",WS,"value-or-field",WS])));var $S=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,uQ],null);$CLJS.Y(kM,bL($CLJS.Hq,$CLJS.H(["field",WS,"value-or-field",WS])));var aT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,kM],null);$CLJS.Y(qM,bL($CLJS.Dq,$CLJS.H(["field",WS,"value-or-field",WS])));var bT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,qM],null);$CLJS.Y(fP,bL($CLJS.NE,$CLJS.H(["field",WS,"min",WS,"max",WS])));
var cT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,fP],null);$CLJS.Y(HP,bL($CLJS.TE,$CLJS.H(["lat-field",WS,"lon-field",WS,"lat-max",WS,"lon-min",WS,"lat-min",WS,"lon-max",WS])));var dT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,HP],null);$CLJS.Y(UM,bL($CLJS.ME,$CLJS.H(["field",$CLJS.KR])));var eT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,UM],null);$CLJS.Y(JN,bL($CLJS.YE,$CLJS.H(["field",$CLJS.KR])));var fT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,JN],null);$CLJS.Y(lN,bL($CLJS.OE,$CLJS.H(["field",$CLJS.KR])));
var gT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,lN],null);$CLJS.Y(sL,bL($CLJS.VE,$CLJS.H(["field",$CLJS.KR])));var hT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,sL],null),iT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tF,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null)],null);$CLJS.Y(yN,bL($CLJS.cF,$CLJS.H(["field",PR,"string-or-field",PR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,iT],null)])));var jT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,yN],null);
$CLJS.Y(rO,bL($CLJS.wF,$CLJS.H(["field",PR,"string-or-field",PR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,iT],null)])));var kT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,rO],null);$CLJS.Y(jN,bL($CLJS.kF,$CLJS.H(["field",PR,"string-or-field",PR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,iT],null)])));var lT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,jN],null);$CLJS.Y($O,bL($CLJS.dF,$CLJS.H(["field",PR,"string-or-field",PR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,iT],null)])));
var mT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$O],null);$CLJS.Y(BL,bL($CLJS.dB,$CLJS.H(["field",$CLJS.KR,"n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,$CLJS.pr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Dj,$CLJS.Qu,$CLJS.Vw,$CLJS.SE],null)],null),"unit",AR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null)],null)],null)])));var nT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,BL],null);
$CLJS.Y(qP,bL($CLJS.JE,$CLJS.H(["segment-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.NJ],null),lR],null)])));var oT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,qP],null);
$CLJS.Y(zM,cL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof RS?new $CLJS.Cc(function(){return RS},$CLJS.ld(jQ,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),TN,"metabase/mbql/schema.cljc",15,$CLJS.kr,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.kr,JM,$CLJS.U($CLJS.oj,YN),BO,$CLJS.U($CLJS.oj,YN),TM,$CLJS.U($CLJS.oj,$CLJS.U($CLJS.ui,YN))],null),$CLJS.Gc,"Schema for a valid and clause.",$CLJS.n(RS)?RS.H:null])):null));return $CLJS.n(a)?a:TN}(),RS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof SS?new $CLJS.Cc(function(){return SS},
$CLJS.ld(FO,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IO,"metabase/mbql/schema.cljc",14,$CLJS.dr,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.dr,JM,$CLJS.U($CLJS.oj,YN),BO,$CLJS.U($CLJS.oj,YN),TM,$CLJS.U($CLJS.oj,$CLJS.U($CLJS.ui,YN))],null),$CLJS.Gc,
"Schema for a valid or clause.",$CLJS.n(SS)?SS.H:null])):null));return $CLJS.n(a)?a:IO}(),SS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof TS?new $CLJS.Cc(function(){return TS},$CLJS.ld(rP,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kO,"metabase/mbql/schema.cljc",15,$CLJS.fr,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,$CLJS.XQ,$CLJS.U($CLJS.oj,YN)],null),$CLJS.Gc,"Schema for a valid not clause.",$CLJS.n(TS)?TS.H:null])):null));return $CLJS.n(a)?a:kO}(),TS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==
typeof ZS?new $CLJS.Cc(function(){return ZS},$CLJS.ld(OO,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MN,"metabase/mbql/schema.cljc",13,$CLJS.Fq,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Fq,$CLJS.lF,$CLJS.U($CLJS.oj,LM),VP,$CLJS.U($CLJS.oj,LM)],
null),$CLJS.Gc,"Schema for a valid \x3c clause.",$CLJS.n(ZS)?ZS.H:null])):null));return $CLJS.n(a)?a:MN}(),ZS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof aT?new $CLJS.Cc(function(){return aT},$CLJS.ld(QQ,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,
1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LN,"metabase/mbql/schema.cljc",14,$CLJS.Hq,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Hq,$CLJS.lF,$CLJS.U($CLJS.oj,LM),VP,$CLJS.U($CLJS.oj,LM)],null),$CLJS.Gc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(aT)?aT.H:null])):null));return $CLJS.n(a)?a:LN}(),aT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&
"undefined"!==typeof gL&&"undefined"!==typeof $S?new $CLJS.Cc(function(){return $S},$CLJS.ld(MO,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NN,"metabase/mbql/schema.cljc",13,$CLJS.Bq,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Bq,$CLJS.lF,$CLJS.U($CLJS.oj,
LM),VP,$CLJS.U($CLJS.oj,LM)],null),$CLJS.Gc,"Schema for a valid \x3e clause.",$CLJS.n($S)?$S.H:null])):null));return $CLJS.n(a)?a:NN}(),$S],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof bT?new $CLJS.Cc(function(){return bT},$CLJS.ld(JQ,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],
[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UN,"metabase/mbql/schema.cljc",14,$CLJS.Dq,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Dq,$CLJS.lF,$CLJS.U($CLJS.oj,LM),VP,$CLJS.U($CLJS.oj,LM)],null),$CLJS.Gc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(bT)?bT.H:null])):null));return $CLJS.n(a)?a:UN}(),bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&
"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof XS?new $CLJS.Cc(function(){return XS},$CLJS.ld(NO,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ON,"metabase/mbql/schema.cljc",13,$CLJS.Jj,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,
[$CLJS.Jj,$CLJS.lF,$CLJS.U($CLJS.oj,VQ),VP,$CLJS.U($CLJS.oj,VQ),yP,$CLJS.U($CLJS.oj,$CLJS.U($CLJS.ui,VQ))],null),$CLJS.Gc,"Schema for a valid \x3d clause.",$CLJS.n(XS)?XS.H:null])):null));return $CLJS.n(a)?a:ON}(),XS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof YS?new $CLJS.Cc(function(){return YS},$CLJS.ld(aM,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,
$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wM,"metabase/mbql/schema.cljc",14,$CLJS.CF,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.CF,$CLJS.lF,$CLJS.U($CLJS.oj,VQ),VP,$CLJS.U($CLJS.oj,VQ),yP,$CLJS.U($CLJS.oj,$CLJS.U($CLJS.ui,VQ))],null),$CLJS.Gc,"Schema for a valid !\x3d clause.",$CLJS.n(YS)?YS.H:null])):null));
return $CLJS.n(a)?a:wM}(),YS],null)])));
$CLJS.Y(GO,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"valid filter expression",$CLJS.wi,function(a){return $CLJS.n(aL(TR,a))?$CLJS.aI:$CLJS.n(aL(QR,a))?dR:$CLJS.n(aL(NR,a))?$CLJS.uj:$CLJS.n(aL(RR,a))?$CLJS.tr:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aI,$CLJS.WR],null),new $CLJS.P(null,2,5,$CLJS.Q,[dR,UR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uj,OR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tr,VR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,cL($CLJS.H([new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof RS?new $CLJS.Cc(function(){return RS},$CLJS.ld(jQ,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TN,"metabase/mbql/schema.cljc",
15,$CLJS.kr,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.kr,JM,$CLJS.U($CLJS.oj,YN),BO,$CLJS.U($CLJS.oj,YN),TM,$CLJS.U($CLJS.oj,$CLJS.U($CLJS.ui,YN))],null),$CLJS.Gc,"Schema for a valid and clause.",$CLJS.n(RS)?RS.H:null])):null));return $CLJS.n(a)?a:TN}(),RS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof SS?new $CLJS.Cc(function(){return SS},$CLJS.ld(FO,new $CLJS.h(null,1,[$CLJS.vj,
!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IO,"metabase/mbql/schema.cljc",14,$CLJS.dr,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.dr,JM,$CLJS.U($CLJS.oj,YN),BO,$CLJS.U($CLJS.oj,YN),TM,$CLJS.U($CLJS.oj,$CLJS.U($CLJS.ui,YN))],null),$CLJS.Gc,"Schema for a valid or clause.",$CLJS.n(SS)?
SS.H:null])):null));return $CLJS.n(a)?a:IO}(),SS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof TS?new $CLJS.Cc(function(){return TS},$CLJS.ld(rP,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),kO,"metabase/mbql/schema.cljc",15,$CLJS.fr,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,$CLJS.XQ,$CLJS.U($CLJS.oj,YN)],null),$CLJS.Gc,"Schema for a valid not clause.",$CLJS.n(TS)?TS.H:null])):null));return $CLJS.n(a)?a:kO}(),TS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof XS?new $CLJS.Cc(function(){return XS},$CLJS.ld(NO,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,
$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ON,"metabase/mbql/schema.cljc",13,$CLJS.Jj,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Jj,$CLJS.lF,$CLJS.U($CLJS.oj,VQ),VP,$CLJS.U($CLJS.oj,VQ),yP,$CLJS.U($CLJS.oj,$CLJS.U($CLJS.ui,VQ))],null),$CLJS.Gc,"Schema for a valid \x3d clause.",$CLJS.n(XS)?XS.H:null])):
null));return $CLJS.n(a)?a:ON}(),XS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof YS?new $CLJS.Cc(function(){return YS},$CLJS.ld(aM,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),wM,"metabase/mbql/schema.cljc",14,$CLJS.CF,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.CF,$CLJS.lF,$CLJS.U($CLJS.oj,VQ),VP,$CLJS.U($CLJS.oj,VQ),yP,$CLJS.U($CLJS.oj,$CLJS.U($CLJS.ui,VQ))],null),$CLJS.Gc,"Schema for a valid !\x3d clause.",$CLJS.n(YS)?YS.H:null])):null));return $CLJS.n(a)?a:wM}(),YS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof ZS?new $CLJS.Cc(function(){return ZS},
$CLJS.ld(OO,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MN,"metabase/mbql/schema.cljc",13,$CLJS.Fq,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Fq,$CLJS.lF,$CLJS.U($CLJS.oj,LM),VP,$CLJS.U($CLJS.oj,LM)],null),$CLJS.Gc,"Schema for a valid \x3c clause.",
$CLJS.n(ZS)?ZS.H:null])):null));return $CLJS.n(a)?a:MN}(),ZS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof $S?new $CLJS.Cc(function(){return $S},$CLJS.ld(MO,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),NN,"metabase/mbql/schema.cljc",13,$CLJS.Bq,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Bq,$CLJS.lF,$CLJS.U($CLJS.oj,LM),VP,$CLJS.U($CLJS.oj,LM)],null),$CLJS.Gc,"Schema for a valid \x3e clause.",$CLJS.n($S)?$S.H:null])):null));return $CLJS.n(a)?a:NN}(),$S],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof aT?new $CLJS.Cc(function(){return aT},$CLJS.ld(QQ,new $CLJS.h(null,
1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LN,"metabase/mbql/schema.cljc",14,$CLJS.Hq,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Hq,$CLJS.lF,$CLJS.U($CLJS.oj,LM),VP,$CLJS.U($CLJS.oj,LM)],null),$CLJS.Gc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(aT)?aT.H:null])):
null));return $CLJS.n(a)?a:LN}(),aT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof bT?new $CLJS.Cc(function(){return bT},$CLJS.ld(JQ,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),UN,"metabase/mbql/schema.cljc",14,$CLJS.Dq,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Dq,$CLJS.lF,$CLJS.U($CLJS.oj,LM),VP,$CLJS.U($CLJS.oj,LM)],null),$CLJS.Gc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(bT)?bT.H:null])):null));return $CLJS.n(a)?a:UN}(),bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof cT?new $CLJS.Cc(function(){return cT},$CLJS.ld(Qka,new $CLJS.h(null,
1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cM,"metabase/mbql/schema.cljc",19,$CLJS.NE,1,804,804,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.NE,$CLJS.lF,$CLJS.U($CLJS.oj,LM),$CLJS.ri,$CLJS.U($CLJS.oj,LM),$CLJS.Aj,$CLJS.U($CLJS.oj,LM)],null),$CLJS.Gc,"Schema for a valid between clause.",
$CLJS.n(cT)?cT.H:null])):null));return $CLJS.n(a)?a:cM}(),cT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof jT?new $CLJS.Cc(function(){return jT},$CLJS.ld(Ska,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$P,"metabase/mbql/schema.cljc",23,$CLJS.cF,1,829,829,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.cF,$CLJS.lF,$CLJS.U($CLJS.oj,UP),vM,$CLJS.U($CLJS.oj,UP),$CLJS.Mi,$CLJS.U($CLJS.oj,$CLJS.U(fQ,pP))],null),$CLJS.Gc,"Schema for a valid starts-with clause.",$CLJS.n(jT)?jT.H:null])):null));return $CLJS.n(a)?a:$P}(),jT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof kT?new $CLJS.Cc(function(){return kT},
$CLJS.ld(Tja,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AM,"metabase/mbql/schema.cljc",21,$CLJS.wF,1,830,830,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.wF,$CLJS.lF,$CLJS.U($CLJS.oj,UP),vM,$CLJS.U($CLJS.oj,UP),$CLJS.Mi,$CLJS.U($CLJS.oj,$CLJS.U(fQ,pP))],null),
$CLJS.Gc,"Schema for a valid ends-with clause.",$CLJS.n(kT)?kT.H:null])):null));return $CLJS.n(a)?a:AM}(),kT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof lT?new $CLJS.Cc(function(){return lT},$CLJS.ld(fka,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,
1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MM,"metabase/mbql/schema.cljc",20,$CLJS.kF,1,831,831,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.kF,$CLJS.lF,$CLJS.U($CLJS.oj,UP),vM,$CLJS.U($CLJS.oj,UP),$CLJS.Mi,$CLJS.U($CLJS.oj,$CLJS.U(fQ,pP))],null),$CLJS.Gc,"Schema for a valid contains clause.",$CLJS.n(lT)?lT.H:null])):null));return $CLJS.n(a)?a:MM}(),lT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==
typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof mT?new $CLJS.Cc(function(){return mT},$CLJS.ld(Jla,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,hQ,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qL,"metabase/mbql/schema.cljc",36,$CLJS.dF,1,834,834,!0,new $CLJS.P(null,
7,5,$CLJS.Q,[$CLJS.dF,$CLJS.lF,$CLJS.U($CLJS.oj,UP),vM,$CLJS.U($CLJS.oj,UP),$CLJS.Mi,$CLJS.U($CLJS.oj,$CLJS.U(fQ,pP))],null),$CLJS.Gc,"Schema for a valid does-not-contain clause.",$CLJS.n(mT)?mT.H:null])):null));return $CLJS.n(a)?a:qL}(),mT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof dT?new $CLJS.Cc(function(){return dT},$CLJS.ld(sla,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),
$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,hQ,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YP,"metabase/mbql/schema.cljc",26,$CLJS.TE,1,807,807,!0,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.TE,Bja,$CLJS.U($CLJS.oj,LM),Ola,$CLJS.U($CLJS.oj,LM),zla,$CLJS.U($CLJS.oj,LM),Eja,$CLJS.U($CLJS.oj,LM),tla,$CLJS.U($CLJS.oj,LM),Aja,$CLJS.U($CLJS.oj,
LM)],null),$CLJS.Gc,"Schema for a valid inside clause.",$CLJS.n(dT)?dT.H:null])):null));return $CLJS.n(a)?a:YP}(),dT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof gT?new $CLJS.Cc(function(){return gT},$CLJS.ld(gka,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,hQ,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,
1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tO,"metabase/mbql/schema.cljc",28,$CLJS.OE,1,821,821,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OE,$CLJS.lF,$CLJS.U($CLJS.oj,EM)],null),$CLJS.Gc,"Schema for a valid is-empty clause.",$CLJS.n(gT)?gT.H:null])):null));return $CLJS.n(a)?a:tO}(),gT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&
"undefined"!==typeof hT?new $CLJS.Cc(function(){return hT},$CLJS.ld(dla,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,hQ,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gP,"metabase/mbql/schema.cljc",29,$CLJS.VE,1,822,822,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VE,$CLJS.lF,$CLJS.U($CLJS.oj,EM)],null),
$CLJS.Gc,"Schema for a valid not-empty clause.",$CLJS.n(hT)?hT.H:null])):null));return $CLJS.n(a)?a:gP}(),hT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof eT?new $CLJS.Cc(function(){return eT},$CLJS.ld(Ela,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,hQ,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,
1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SM,"metabase/mbql/schema.cljc",27,$CLJS.ME,1,816,816,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ME,$CLJS.lF,$CLJS.U($CLJS.oj,EM)],null),$CLJS.Gc,"Schema for a valid is-null clause.",$CLJS.n(eT)?eT.H:null])):null));return $CLJS.n(a)?a:SM}(),eT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&
"undefined"!==typeof fT?new $CLJS.Cc(function(){return fT},$CLJS.ld(ska,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,hQ,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hM,"metabase/mbql/schema.cljc",28,$CLJS.YE,1,817,817,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YE,$CLJS.lF,$CLJS.U($CLJS.oj,EM)],null),
$CLJS.Gc,"Schema for a valid not-null clause.",$CLJS.n(fT)?fT.H:null])):null));return $CLJS.n(a)?a:hM}(),fT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof nT?new $CLJS.Cc(function(){return nT},$CLJS.ld(sja,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,hQ,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,
1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FP,"metabase/mbql/schema.cljc",33,$CLJS.dB,1,856,856,!0,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.dB,$CLJS.lF,$CLJS.U($CLJS.oj,EM),$CLJS.Ij,$CLJS.U($CLJS.oj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,$CLJS.pr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Dj,$CLJS.Qu,$CLJS.Vw,$CLJS.SE],null)],null)),$CLJS.di,$CLJS.U($CLJS.oj,SN),$CLJS.Mi,$CLJS.U($CLJS.oj,$CLJS.U(fQ,Vka))],null),$CLJS.Gc,"Schema for a valid time-interval clause.",
$CLJS.n(nT)?nT.H:null])):null));return $CLJS.n(a)?a:FP}(),nT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof oT?new $CLJS.Cc(function(){return oT},$CLJS.ld(Aka,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,hQ,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),HQ,"metabase/mbql/schema.cljc",27,$CLJS.JE,1,872,872,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JE,Ija,$CLJS.U($CLJS.oj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,jla,UL],null))],null),$CLJS.Gc,"Schema for a valid segment clause.",$CLJS.n(oT)?oT.H:null])):null));return $CLJS.n(a)?a:HQ}(),oT],null)]))],null)],null));
$CLJS.Y(sP,bL($CLJS.UE,$CLJS.H(["clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.h(null,1,[$CLJS.ts,":case subclause"],null),$CLJS.QS,aS],null)],null)],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.ts,":case options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.yr,!0],null),aS],null)],null)],null)])));
var pT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,sP],null);
$CLJS.Y(ZP,cL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof nS?new $CLJS.Cc(function(){return nS},$CLJS.ld(QO,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.YO,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),EN,"metabase/mbql/schema.cljc",51,$CLJS.Jq,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Jq,$CLJS.AD,$CLJS.U($CLJS.oj,UO),qQ,$CLJS.U($CLJS.oj,UO),IM,$CLJS.U($CLJS.oj,$CLJS.U($CLJS.ui,UO))],null),$CLJS.Gc,"Schema for a valid + clause.",$CLJS.n(nS)?nS.H:null])):null));return $CLJS.n(a)?a:EN}(),nS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof oS?new $CLJS.Cc(function(){return oS},
$CLJS.ld(Mka,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.YO,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FN,"metabase/mbql/schema.cljc",51,$CLJS.Zr,1,615,615,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Zr,$CLJS.AD,$CLJS.U($CLJS.oj,PP),
qQ,$CLJS.U($CLJS.oj,UO),IM,$CLJS.U($CLJS.oj,$CLJS.U($CLJS.ui,UO))],null),$CLJS.Gc,"Schema for a valid - clause.",$CLJS.n(oS)?oS.H:null])):null));return $CLJS.n(a)?a:FN}(),oS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL?new $CLJS.Cc(function(){return qS},$CLJS.ld(Lka,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,hO,$CLJS.Ai,$CLJS.Si,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],
[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.YO,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.bz,"metabase/mbql/schema.cljc",$CLJS.GF,1,618,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.GF,$CLJS.AD,$CLJS.U($CLJS.oj,PP),qQ,$CLJS.U($CLJS.oj,PP),IM,$CLJS.U($CLJS.oj,$CLJS.U($CLJS.ui,PP))],null),$CLJS.Gc,"Schema for a valid / clause.",$CLJS.n(qS)?qS.H:null])):null));return $CLJS.n(a)?
a:$CLJS.bz}(),qS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof rS?new $CLJS.Cc(function(){return rS},$CLJS.ld(Nka,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.YO,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),DN,"metabase/mbql/schema.cljc",51,$CLJS.Kq,1,620,620,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Kq,$CLJS.AD,$CLJS.U($CLJS.oj,PP),qQ,$CLJS.U($CLJS.oj,PP),IM,$CLJS.U($CLJS.oj,$CLJS.U($CLJS.ui,PP))],null),$CLJS.Gc,"Schema for a valid * clause.",$CLJS.n(rS)?rS.H:null])):null));return $CLJS.n(a)?a:DN}(),rS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof cS?new $CLJS.Cc(function(){return cS},
$CLJS.ld(CP,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.YO,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RP,"metabase/mbql/schema.cljc",58,$CLJS.oF,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.oF,zQ,$CLJS.U($CLJS.oj,vL),yQ,$CLJS.U($CLJS.oj,
vL),IM,$CLJS.U($CLJS.oj,$CLJS.U($CLJS.ui,vL))],null),$CLJS.Gc,"Schema for a valid coalesce clause.",$CLJS.n(cS)?cS.H:null])):null));return $CLJS.n(a)?a:RP}(),cS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof eS?new $CLJS.Cc(function(){return eS},$CLJS.ld(cla,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,
$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.YO,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jP,"metabase/mbql/schema.cljc",56,$CLJS.Pw,1,585,585,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Pw,$CLJS.lx,$CLJS.U($CLJS.oj,UP)],null),$CLJS.Gc,"Schema for a valid length clause.",$CLJS.n(eS)?eS.H:null])):null));return $CLJS.n(a)?a:jP}(),eS],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof sS?new $CLJS.Cc(function(){return sS},$CLJS.ld(Uja,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.YO,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),eO,"metabase/mbql/schema.cljc",55,$CLJS.jF,1,622,622,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jF,$CLJS.AD,$CLJS.U($CLJS.oj,PP)],null),$CLJS.Gc,"Schema for a valid floor clause.",$CLJS.n(sS)?sS.H:null])):null));return $CLJS.n(a)?a:eO}(),sS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof tS?new $CLJS.Cc(function(){return tS},$CLJS.ld(Oja,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),
$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.YO,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TL,"metabase/mbql/schema.cljc",54,$CLJS.GE,1,625,625,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GE,$CLJS.AD,$CLJS.U($CLJS.oj,PP)],null),$CLJS.Gc,"Schema for a valid ceil clause.",$CLJS.n(tS)?
tS.H:null])):null));return $CLJS.n(a)?a:TL}(),tS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof uS?new $CLJS.Cc(function(){return uS},$CLJS.ld(cka,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.YO,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,
1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VN,"metabase/mbql/schema.cljc",55,$CLJS.qF,1,628,628,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qF,$CLJS.AD,$CLJS.U($CLJS.oj,PP)],null),$CLJS.Gc,"Schema for a valid round clause.",$CLJS.n(uS)?uS.H:null])):null));return $CLJS.n(a)?a:VN}(),uS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&
"undefined"!==typeof vS?new $CLJS.Cc(function(){return vS},$CLJS.ld(Kla,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.YO,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xO,"metabase/mbql/schema.cljc",53,$CLJS.LE,1,631,631,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.LE,$CLJS.AD,$CLJS.U($CLJS.oj,PP)],null),$CLJS.Gc,"Schema for a valid abs clause.",$CLJS.n(vS)?vS.H:null])):null));return $CLJS.n(a)?a:xO}(),vS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof wS?new $CLJS.Cc(function(){return wS},$CLJS.ld(xla,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,
dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[NQ,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iQ,"metabase/mbql/schema.cljc",69,$CLJS.IE,1,634,634,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.IE,$CLJS.AD,$CLJS.U($CLJS.oj,PP),qQ,$CLJS.U($CLJS.oj,PP)],null),$CLJS.Gc,"Schema for a valid power clause.",$CLJS.n(wS)?wS.H:null])):null));return $CLJS.n(a)?a:iQ}(),wS],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof xS?new $CLJS.Cc(function(){return xS},$CLJS.ld(vka,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[NQ,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),zN,"metabase/mbql/schema.cljc",68,$CLJS.ZE,1,637,637,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZE,$CLJS.AD,$CLJS.U($CLJS.oj,PP)],null),$CLJS.Gc,"Schema for a valid sqrt clause.",$CLJS.n(xS)?xS.H:null])):null));return $CLJS.n(a)?a:zN}(),xS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof yS?new $CLJS.Cc(function(){return yS},$CLJS.ld(yla,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),
$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[NQ,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XN,"metabase/mbql/schema.cljc",67,$CLJS.CE,1,640,640,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CE,$CLJS.AD,$CLJS.U($CLJS.oj,PP)],null),$CLJS.Gc,"Schema for a valid exp clause.",$CLJS.n(yS)?
yS.H:null])):null));return $CLJS.n(a)?a:XN}(),yS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof zS?new $CLJS.Cc(function(){return zS},$CLJS.ld(Hja,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[NQ,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,
1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SQ,"metabase/mbql/schema.cljc",67,$CLJS.IF,1,643,643,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IF,$CLJS.AD,$CLJS.U($CLJS.oj,PP)],null),$CLJS.Gc,"Schema for a valid log clause.",$CLJS.n(zS)?zS.H:null])):null));return $CLJS.n(a)?a:SQ}(),zS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&
"undefined"!==typeof pT?new $CLJS.Cc(function(){return pT},$CLJS.ld(GL,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.FE,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oO,"metabase/mbql/schema.cljc",61,$CLJS.UE,1,909,909,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.UE,$CLJS.bw,$CLJS.U($CLJS.oj,YM),$CLJS.Mi,$CLJS.U($CLJS.oj,$CLJS.U(fQ,HO))],null),$CLJS.Gc,"Schema for a valid case clause.",$CLJS.n(pT)?pT.H:null])):null));return $CLJS.n(a)?a:oO}(),pT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof AS?new $CLJS.Cc(function(){return AS},$CLJS.ld(Kja,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,
$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.YA,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yO,"metabase/mbql/schema.cljc",65,$CLJS.YA,1,656,656,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.YA,Xja,$CLJS.U($CLJS.oj,cR),Yja,$CLJS.U($CLJS.oj,cR),$CLJS.di,$CLJS.U($CLJS.oj,Dka)],null),$CLJS.Gc,"Schema for a valid datetime-diff clause.",
$CLJS.n(AS)?AS.H:null])):null));return $CLJS.n(a)?a:yO}(),AS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof BS?new $CLJS.Cc(function(){return BS},$CLJS.ld(gla,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.ld(NP,
new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RO,"metabase/mbql/schema.cljc",71,$CLJS.cB,1,661,661,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.cB,$CLJS.aI,$CLJS.U($CLJS.oj,cR),$CLJS.di,$CLJS.U($CLJS.oj,Dla),$CLJS.hi,$CLJS.U($CLJS.oj,$CLJS.U(fQ,kR))],null),$CLJS.Gc,"Schema for a valid temporal-extract clause.",$CLJS.n(BS)?BS.H:null])):null));return $CLJS.n(a)?a:RO}(),BS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof CS?new $CLJS.Cc(function(){return CS},$CLJS.ld(oka,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,hQ,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),pM,"metabase/mbql/schema.cljc",71,$CLJS.WH,1,667,667,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WH,$CLJS.AO,$CLJS.U($CLJS.oj,cR)],null),$CLJS.Gc,"Schema for a valid get-year clause.",$CLJS.n(CS)?CS.H:null])):null));return $CLJS.n(a)?a:pM}(),CS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof DS?new $CLJS.Cc(function(){return DS},$CLJS.ld(dka,new $CLJS.h(null,1,[$CLJS.vj,!0],
null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,hQ,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PO,"metabase/mbql/schema.cljc",74,$CLJS.lI,1,670,670,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lI,$CLJS.AO,$CLJS.U($CLJS.oj,cR)],null),$CLJS.Gc,"Schema for a valid get-quarter clause.",
$CLJS.n(DS)?DS.H:null])):null));return $CLJS.n(a)?a:PO}(),DS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof ES?new $CLJS.Cc(function(){return ES},$CLJS.ld(Kka,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,hQ,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),
$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lP,"metabase/mbql/schema.cljc",72,$CLJS.PH,1,673,673,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PH,$CLJS.AO,$CLJS.U($CLJS.oj,cR)],null),$CLJS.Gc,"Schema for a valid get-month clause.",$CLJS.n(ES)?ES.H:null])):null));return $CLJS.n(a)?a:lP}(),ES],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==
typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof FS?new $CLJS.Cc(function(){return FS},$CLJS.ld(Cla,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,hQ,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kP,"metabase/mbql/schema.cljc",
71,$CLJS.aB,1,676,676,!0,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.aB,$CLJS.AO,$CLJS.U($CLJS.oj,cR),$CLJS.hi,$CLJS.U($CLJS.oj,$CLJS.U(fQ,kR))],null),$CLJS.Gc,"Schema for a valid get-week clause.",$CLJS.n(FS)?FS.H:null])):null));return $CLJS.n(a)?a:kP}(),FS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof GS?new $CLJS.Cc(function(){return GS},$CLJS.ld(yja,new $CLJS.h(null,1,[$CLJS.vj,
!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,hQ,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dP,"metabase/mbql/schema.cljc",70,$CLJS.eI,1,680,680,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eI,$CLJS.AO,$CLJS.U($CLJS.oj,cR)],null),$CLJS.Gc,"Schema for a valid get-day clause.",
$CLJS.n(GS)?GS.H:null])):null));return $CLJS.n(a)?a:dP}(),GS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof HS?new $CLJS.Cc(function(){return HS},$CLJS.ld(Eka,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,hQ,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),
$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VO,"metabase/mbql/schema.cljc",78,$CLJS.hI,1,683,683,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hI,$CLJS.AO,$CLJS.U($CLJS.oj,cR)],null),$CLJS.Gc,"Schema for a valid get-day-of-week clause.",$CLJS.n(HS)?HS.H:null])):null));return $CLJS.n(a)?a:VO}(),HS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==
typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof IS?new $CLJS.Cc(function(){return IS},$CLJS.ld(Cka,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,hQ,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wL,"metabase/mbql/schema.cljc",
71,$CLJS.jI,1,686,686,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jI,$CLJS.aI,$CLJS.U($CLJS.oj,cR)],null),$CLJS.Gc,"Schema for a valid get-hour clause.",$CLJS.n(IS)?IS.H:null])):null));return $CLJS.n(a)?a:wL}(),IS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof JS?new $CLJS.Cc(function(){return JS},$CLJS.ld(kla,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,
$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,hQ,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WQ,"metabase/mbql/schema.cljc",73,$CLJS.gI,1,689,689,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gI,$CLJS.aI,$CLJS.U($CLJS.oj,cR)],null),$CLJS.Gc,"Schema for a valid get-minute clause.",$CLJS.n(JS)?JS.H:null])):null));
return $CLJS.n(a)?a:WQ}(),JS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof KS?new $CLJS.Cc(function(){return KS},$CLJS.ld(zka,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,hQ,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,
[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qO,"metabase/mbql/schema.cljc",73,$CLJS.VH,1,692,692,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VH,$CLJS.aI,$CLJS.U($CLJS.oj,cR)],null),$CLJS.Gc,"Schema for a valid get-second clause.",$CLJS.n(KS)?KS.H:null])):null));return $CLJS.n(a)?a:qO}(),KS],null)])));
$CLJS.Y(LL,cL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof dS?new $CLJS.Cc(function(){return dS},$CLJS.ld(ala,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.YO,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),tP,"metabase/mbql/schema.cljc",59,$CLJS.DE,1,582,582,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.DE,$CLJS.lx,$CLJS.U($CLJS.oj,UP),$CLJS.vu,$CLJS.U($CLJS.oj,$ja),$CLJS.Pw,$CLJS.U($CLJS.oj,$CLJS.U(fQ,PP))],null),$CLJS.Gc,"Schema for a valid substring clause.",$CLJS.n(dS)?dS.H:null])):null));return $CLJS.n(a)?a:tP}(),dS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof fS?new $CLJS.Cc(function(){return fS},
$CLJS.ld(Ala,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.YO,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QP,"metabase/mbql/schema.cljc",54,$CLJS.BF,1,588,588,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BF,$CLJS.lx,$CLJS.U($CLJS.oj,UP)],
null),$CLJS.Gc,"Schema for a valid trim clause.",$CLJS.n(fS)?fS.H:null])):null));return $CLJS.n(a)?a:QP}(),fS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof hS?new $CLJS.Cc(function(){return hS},$CLJS.ld(Fka,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,
1,[$CLJS.YO,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zP,"metabase/mbql/schema.cljc",55,$CLJS.DF,1,594,594,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DF,$CLJS.lx,$CLJS.U($CLJS.oj,UP)],null),$CLJS.Gc,"Schema for a valid ltrim clause.",$CLJS.n(hS)?hS.H:null])):null));return $CLJS.n(a)?a:zP}(),hS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&
"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof gS?new $CLJS.Cc(function(){return gS},$CLJS.ld(bla,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.YO,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CO,"metabase/mbql/schema.cljc",
55,$CLJS.KF,1,591,591,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KF,$CLJS.lx,$CLJS.U($CLJS.oj,UP)],null),$CLJS.Gc,"Schema for a valid rtrim clause.",$CLJS.n(gS)?gS.H:null])):null));return $CLJS.n(a)?a:CO}(),gS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof kS?new $CLJS.Cc(function(){return kS},$CLJS.ld(tja,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,
$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.YO,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BQ,"metabase/mbql/schema.cljc",57,$CLJS.JF,1,603,603,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.JF,$CLJS.lx,$CLJS.U($CLJS.oj,UP),Fja,$CLJS.U($CLJS.oj,$CLJS.uj),$CLJS.iz,$CLJS.U($CLJS.oj,$CLJS.uj)],null),$CLJS.Gc,"Schema for a valid replace clause.",
$CLJS.n(kS)?kS.H:null])):null));return $CLJS.n(a)?a:BQ}(),kS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof jS?new $CLJS.Cc(function(){return jS},$CLJS.ld(ela,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.YO,"null"],null),null),$CLJS.ld(NP,
new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fR,"metabase/mbql/schema.cljc",55,$CLJS.ps,1,600,600,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,$CLJS.lx,$CLJS.U($CLJS.oj,UP)],null),$CLJS.Gc,"Schema for a valid lower clause.",$CLJS.n(jS)?jS.H:null])):null));return $CLJS.n(a)?a:fR}(),jS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==
typeof gL&&"undefined"!==typeof iS?new $CLJS.Cc(function(){return iS},$CLJS.ld(Bla,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.YO,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cQ,"metabase/mbql/schema.cljc",55,$CLJS.js,1,597,597,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.js,$CLJS.lx,$CLJS.U($CLJS.oj,UP)],null),$CLJS.Gc,"Schema for a valid upper clause.",$CLJS.n(iS)?iS.H:null])):null));return $CLJS.n(a)?a:cQ}(),iS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof lS?new $CLJS.Cc(function(){return lS},$CLJS.ld(Uka,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,
$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.YO,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qN,"metabase/mbql/schema.cljc",56,$CLJS.KE,1,606,606,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.KE,zQ,$CLJS.U($CLJS.oj,UP),yQ,$CLJS.U($CLJS.oj,UP),IM,$CLJS.U($CLJS.oj,$CLJS.U($CLJS.ui,UP))],null),$CLJS.Gc,"Schema for a valid concat clause.",
$CLJS.n(lS)?lS.H:null])):null));return $CLJS.n(a)?a:qN}(),lS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof mS?new $CLJS.Cc(function(){return mS},$CLJS.ld($ka,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.YO,"null",$CLJS.ij,"null"],
null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nM,"metabase/mbql/schema.cljc",74,$CLJS.nF,1,609,609,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.nF,$CLJS.lx,$CLJS.U($CLJS.oj,UP),$CLJS.hz,$CLJS.U($CLJS.oj,$CLJS.uj)],null),$CLJS.Gc,"Schema for a valid regex-match-first clause.",$CLJS.n(mS)?mS.H:null])):null));return $CLJS.n(a)?a:nM}(),mS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==
typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof cS?new $CLJS.Cc(function(){return cS},$CLJS.ld(CP,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.YO,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
RP,"metabase/mbql/schema.cljc",58,$CLJS.oF,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.oF,zQ,$CLJS.U($CLJS.oj,vL),yQ,$CLJS.U($CLJS.oj,vL),IM,$CLJS.U($CLJS.oj,$CLJS.U($CLJS.ui,vL))],null),$CLJS.Gc,"Schema for a valid coalesce clause.",$CLJS.n(cS)?cS.H:null])):null));return $CLJS.n(a)?a:RP}(),cS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof pT?new $CLJS.Cc(function(){return pT},
$CLJS.ld(GL,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.FE,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oO,"metabase/mbql/schema.cljc",61,$CLJS.UE,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.UE,$CLJS.bw,$CLJS.U($CLJS.oj,YM),
$CLJS.Mi,$CLJS.U($CLJS.oj,$CLJS.U(fQ,HO))],null),$CLJS.Gc,"Schema for a valid case clause.",$CLJS.n(pT)?pT.H:null])):null));return $CLJS.n(a)?a:oO}(),pT],null)])));
$CLJS.qT=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,":field or :expression reference or expression",$CLJS.wi,function(a){return $CLJS.n(aL(QR,a))?dR:$CLJS.n(aL(NR,a))?$CLJS.uj:$CLJS.n(aL(RR,a))?$CLJS.tr:$CLJS.n(aL(TR,a))?$CLJS.aI:$CLJS.n(aL($CLJS.UE,a))?$CLJS.UE:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[dR,UR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uj,OR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tr,VR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aI,$CLJS.WR],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UE,pT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.KR],null)],null);$CLJS.Y(KQ,bL($CLJS.Rw,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,$CLJS.KR],null)])));var rT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,KQ],null);$CLJS.Y(zO,bL($CLJS.mF,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,$CLJS.KR],null)])));var sT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,zO],null);$CLJS.Y(JL,bL($CLJS.fF,$CLJS.H(["field-or-expression",$CLJS.qT])));
var tT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,JL],null);$CLJS.Y(CQ,bL($CLJS.HF,$CLJS.H(["field-or-expression",$CLJS.qT])));var uT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,CQ],null);$CLJS.Y(iN,bL($CLJS.QE,$CLJS.H(["field-or-expression",$CLJS.qT])));var vT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,iN],null);$CLJS.Y(SP,bL($CLJS.xF,$CLJS.H(["field-or-expression",$CLJS.qT])));var wT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,SP],null);$CLJS.Y(dN,bL($CLJS.ri,$CLJS.H(["field-or-expression",$CLJS.qT])));
var xT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,dN],null);$CLJS.Y(QM,bL($CLJS.Aj,$CLJS.H(["field-or-expression",$CLJS.qT])));var yT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,QM],null);$CLJS.Y(KM,bL($CLJS.FF,$CLJS.H(["field-or-expression",$CLJS.qT,"pred",$CLJS.QS])));var zT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,KM],null);$CLJS.Y(aN,bL($CLJS.uF,$CLJS.H(["pred",$CLJS.QS])));var AT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,aN],null);$CLJS.Y(JP,bL($CLJS.sF,$CLJS.H(["pred",$CLJS.QS])));
var BT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,JP],null);$CLJS.Y(gM,bL($CLJS.AF,$CLJS.H(["field-or-expression",$CLJS.qT])));var CT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,gM],null);$CLJS.Y(iL,bL($CLJS.RE,$CLJS.H(["field-or-expression",$CLJS.qT])));var DT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,iL],null);$CLJS.Y(mN,bL($CLJS.yF,$CLJS.H(["field-or-expression",$CLJS.qT])));var ET=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,mN],null);$CLJS.Y($N,bL($CLJS.XE,$CLJS.H(["field-or-expression",$CLJS.qT,"percentile",ZR])));
var FT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$N],null);$CLJS.Y(MP,bL($CLJS.gF,$CLJS.H(["metric-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.LJ],null),lR],null)])));var GT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,MP],null);
$CLJS.Y(IP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"unnamed aggregation clause or numeric expression",$CLJS.wi,function(a){return $CLJS.n(aL(QR,a))?cO:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[cO,UR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,cL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof tT?new $CLJS.Cc(function(){return tT},$CLJS.ld(Zka,
new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.FE,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lO,"metabase/mbql/schema.cljc",60,$CLJS.fF,1,957,957,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fF,dQ,$CLJS.U($CLJS.oj,WP)],null),$CLJS.Gc,"Schema for a valid avg clause.",
$CLJS.n(tT)?tT.H:null])):null));return $CLJS.n(a)?a:lO}(),tT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof uT?new $CLJS.Cc(function(){return uT},$CLJS.ld(kka,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.FE,"null"],null),null),$CLJS.ld(NP,
new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pQ,"metabase/mbql/schema.cljc",64,$CLJS.HF,1,958,958,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HF,dQ,$CLJS.U($CLJS.oj,WP)],null),$CLJS.Gc,"Schema for a valid cum-sum clause.",$CLJS.n(uT)?uT.H:null])):null));return $CLJS.n(a)?a:pQ}(),uT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==
typeof gL&&"undefined"!==typeof vT?new $CLJS.Cc(function(){return vT},$CLJS.ld(bka,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.FE,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pO,"metabase/mbql/schema.cljc",65,$CLJS.QE,1,959,959,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QE,dQ,$CLJS.U($CLJS.oj,WP)],null),$CLJS.Gc,"Schema for a valid distinct clause.",$CLJS.n(vT)?vT.H:null])):null));return $CLJS.n(a)?a:pO}(),vT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof CT?new $CLJS.Cc(function(){return CT},$CLJS.ld(ula,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,
$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.vF,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DL,"metabase/mbql/schema.cljc",76,$CLJS.AF,1,973,973,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AF,dQ,$CLJS.U($CLJS.oj,WP)],null),$CLJS.Gc,"Schema for a valid stddev clause.",$CLJS.n(CT)?CT.H:null])):null));return $CLJS.n(a)?a:DL}(),CT],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof wT?new $CLJS.Cc(function(){return wT},$CLJS.ld(Pka,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.FE,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ZL,"metabase/mbql/schema.cljc",60,$CLJS.xF,1,960,960,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xF,dQ,$CLJS.U($CLJS.oj,WP)],null),$CLJS.Gc,"Schema for a valid sum clause.",$CLJS.n(wT)?wT.H:null])):null));return $CLJS.n(a)?a:ZL}(),wT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof xT?new $CLJS.Cc(function(){return xT},$CLJS.ld(Cja,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,
$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.FE,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Mr,"metabase/mbql/schema.cljc",60,$CLJS.ri,1,961,961,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ri,dQ,$CLJS.U($CLJS.oj,WP)],null),$CLJS.Gc,"Schema for a valid min clause.",$CLJS.n(xT)?xT.H:
null])):null));return $CLJS.n(a)?a:$CLJS.Mr}(),xT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof yT?new $CLJS.Cc(function(){return yT},$CLJS.ld(xja,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.FE,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,
1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Qr,"metabase/mbql/schema.cljc",60,$CLJS.Aj,1,962,962,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,dQ,$CLJS.U($CLJS.oj,WP)],null),$CLJS.Gc,"Schema for a valid max clause.",$CLJS.n(yT)?yT.H:null])):null));return $CLJS.n(a)?a:$CLJS.Qr}(),yT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&
"undefined"!==typeof GT?new $CLJS.Cc(function(){return GT},$CLJS.ld(Zja,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pN,"metabase/mbql/schema.cljc",18,$CLJS.gF,1,994,994,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gF,lka,$CLJS.U($CLJS.oj,new $CLJS.P(null,3,
5,$CLJS.Q,[$CLJS.dr,pka,UL],null))],null),$CLJS.Gc,"Schema for a valid metric clause.",$CLJS.n(GT)?GT.H:null])):null));return $CLJS.n(a)?a:pN}(),GT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof BT?new $CLJS.Cc(function(){return BT},$CLJS.ld(xka,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,
$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.FE,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hN,"metabase/mbql/schema.cljc",62,$CLJS.sF,1,970,970,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sF,$CLJS.wn,$CLJS.U($CLJS.oj,YN)],null),$CLJS.Gc,"Schema for a valid share clause.",$CLJS.n(BT)?BT.H:null])):null));return $CLJS.n(a)?a:hN}(),BT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof AT?new $CLJS.Cc(function(){return AT},$CLJS.ld(Dja,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.FE,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),VL,"metabase/mbql/schema.cljc",68,$CLJS.uF,1,967,967,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uF,$CLJS.wn,$CLJS.U($CLJS.oj,YN)],null),$CLJS.Gc,"Schema for a valid count-where clause.",$CLJS.n(AT)?AT.H:null])):null));return $CLJS.n(a)?a:VL}(),AT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof zT?new $CLJS.Cc(function(){return zT},$CLJS.ld(Ila,new $CLJS.h(null,1,[$CLJS.vj,!0],
null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.FE,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mO,"metabase/mbql/schema.cljc",66,$CLJS.FF,1,964,964,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.FF,dQ,$CLJS.U($CLJS.oj,WP),$CLJS.wn,$CLJS.U($CLJS.oj,YN)],null),$CLJS.Gc,"Schema for a valid sum-where clause.",
$CLJS.n(zT)?zT.H:null])):null));return $CLJS.n(a)?a:mO}(),zT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof pT?new $CLJS.Cc(function(){return pT},$CLJS.ld(GL,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.FE,"null"],null),null),$CLJS.ld(NP,
new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oO,"metabase/mbql/schema.cljc",61,$CLJS.UE,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.UE,$CLJS.bw,$CLJS.U($CLJS.oj,YM),$CLJS.Mi,$CLJS.U($CLJS.oj,$CLJS.U(fQ,HO))],null),$CLJS.Gc,"Schema for a valid case clause.",$CLJS.n(pT)?pT.H:null])):null));return $CLJS.n(a)?a:oO}(),pT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&
"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof ET?new $CLJS.Cc(function(){return ET},$CLJS.ld(nla,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.hF,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nQ,"metabase/mbql/schema.cljc",
68,$CLJS.yF,1,979,979,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yF,dQ,$CLJS.U($CLJS.oj,WP)],null),$CLJS.Gc,"Schema for a valid median clause.",$CLJS.n(ET)?ET.H:null])):null));return $CLJS.n(a)?a:nQ}(),ET],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof FT?new $CLJS.Cc(function(){return FT},$CLJS.ld(Jka,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,
hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.hF,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LP,"metabase/mbql/schema.cljc",72,$CLJS.XE,1,982,982,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.XE,dQ,$CLJS.U($CLJS.oj,WP),$CLJS.XE,$CLJS.U($CLJS.oj,PP)],null),$CLJS.Gc,"Schema for a valid percentile clause.",$CLJS.n(FT)?FT.H:null])):
null));return $CLJS.n(a)?a:LP}(),FT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof DT?new $CLJS.Cc(function(){return DT},$CLJS.ld(Nja,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),yL,"metabase/mbql/schema.cljc",77,$CLJS.RE,1,976,976,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RE,dQ,$CLJS.U($CLJS.oj,WP)],null),$CLJS.Gc,"Schema for a valid var clause.",$CLJS.n(DT)?DT.H:null])):null));return $CLJS.n(a)?a:yL}(),DT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof sT?new $CLJS.Cc(function(){return sT},$CLJS.ld(zja,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,
$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,hQ,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.FE,"null"],null),null),$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZN,"metabase/mbql/schema.cljc",74,$CLJS.mF,1,947,947,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mF,$CLJS.lF,$CLJS.U($CLJS.oj,$CLJS.U(fQ,EM))],null),$CLJS.Gc,"Schema for a valid cum-count clause.",
$CLJS.n(sT)?sT.H:null])):null));return $CLJS.n(a)?a:ZN}(),sT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof rT?new $CLJS.Cc(function(){return rT},$CLJS.ld(aka,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([fO,$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,hQ,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.FE,"null"],null),null),
$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.gi,"metabase/mbql/schema.cljc",70,$CLJS.Rw,1,946,946,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rw,$CLJS.lF,$CLJS.U($CLJS.oj,$CLJS.U(fQ,EM))],null),$CLJS.Gc,"Schema for a valid count clause.",$CLJS.n(rT)?rT.H:null])):null));return $CLJS.n(a)?a:$CLJS.gi}(),rT],null)]))],null)],null));
$CLJS.Y(aP,bL($CLJS.tL,$CLJS.H(["aggregation",IP,"options",new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.ts,":aggregation-options options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.yr,!0],null),lR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),lR],null)],null)])));
$CLJS.Y(eQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"aggregation clause or numeric expression",$CLJS.wi,function(a){return $CLJS.n(aL($CLJS.tL,a))?$CLJS.tL:XM}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,aP],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[XM,IP],null)],null));$CLJS.Y(CL,bL($CLJS.iF,$CLJS.H(["field",MR])));var HT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,CL],null);$CLJS.Y(bO,bL($CLJS.bF,$CLJS.H(["field",MR])));
IT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,bO],null);
$la=cL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof HT?new $CLJS.Cc(function(){return HT},$CLJS.ld(rla,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
IN,"metabase/mbql/schema.cljc",15,$CLJS.iF,1,1049,1049,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iF,$CLJS.lF,$CLJS.U($CLJS.oj,xQ)],null),$CLJS.Gc,"Schema for a valid asc clause.",$CLJS.n(HT)?HT.H:null])):null));return $CLJS.n(a)?a:IN}(),HT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof IT?new $CLJS.Cc(function(){return IT},$CLJS.ld(jka,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,
$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DP,"metabase/mbql/schema.cljc",16,$CLJS.bF,1,1050,1050,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bF,$CLJS.lF,$CLJS.U($CLJS.oj,xQ)],null),$CLJS.Gc,"Schema for a valid desc clause.",$CLJS.n(IT)?IT.H:null])):null));return $CLJS.n(a)?a:DP}(),IT],null)]));
JT=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Dj,$CLJS.FL,$CLJS.uM,$CLJS.EE,$CLJS.Ps,$CLJS.Hj,$CLJS.AO],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,lR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nD,lR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gi,new $CLJS.h(null,1,[$CLJS.yr,!0],null),lR],null)],null);
ama=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,JT,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,$CLJS.FL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rL,lR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SO,oR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TO,new $CLJS.h(null,1,[$CLJS.yr,!0],null),oR],null)],null)],null);
bma=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,JT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,$CLJS.uM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aQ,oR],null)],null)],null);
KT=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,JT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WM,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null)],null)],null);cma=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,oQ],null);
dma=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,KT,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,$CLJS.EE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EE,JR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,YL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Mi,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ir,$CLJS.Ei,$CLJS.ur],
null)],null)],null)],null)],null);$CLJS.LT=new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.AO,null,$CLJS.Ps,null,$CLJS.tr,null,$CLJS.Hj,null],null),null);ema=$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Dj],null),$CLJS.LT);
$CLJS.Y(wP,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,1,[$CLJS.wi,$CLJS.Ri],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EE,dma],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FL,ama],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uM,bma],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xn,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,KT,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,ema],null)],null)],null)],null)],null));
var MT=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EP,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ir,lR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,wP],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ts,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Le(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,
$CLJS.T.h(b))},a)}],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kN,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,lR],null)],null)],null),fma=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,MT,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HE,$CLJS.ur],null)],null)],null),NT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,eP],null),OT=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,1,[$CLJS.wi,function(a){return $CLJS.n($CLJS.gD($CLJS.td,
$CLJS.hP)(a))?$CLJS.hP:$CLJS.mD}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hP,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,MT,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hP,$CLJS.ur],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mD,NT],null)],null),PT=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,lR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dC,mR],null),new $CLJS.P(null,2,5,$CLJS.Q,[Rka,lR],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.CN,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,nR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RM,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.zj],null)],null)],null),QT=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,Sla,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.h(null,1,[$CLJS.ts,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),gma=$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Dj],null),
new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.iE,null,$CLJS.SD,null,$CLJS.bE,null,$CLJS.fE,null],null),null)),RT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,mQ],null);
$CLJS.Y(gN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dO,new $CLJS.h(null,1,[$CLJS.yr,!0],null),QT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GP,new $CLJS.h(null,1,[$CLJS.yr,!0],null),OT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OQ,$CLJS.QS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),gma],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.dr,new $CLJS.h(null,1,[$CLJS.ts,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,$CLJS.wx,$CLJS.cz],null),RT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),lR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YJ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,qR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QN,new $CLJS.h(null,
1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aj,PT],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ts,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.gD($CLJS.zz($CLJS.dO,$CLJS.GP),$CLJS.Oe($CLJS.gD($CLJS.dO,$CLJS.GP)))],null)],null));var hma=$CLJS.Q,ST;var TT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,gN],null)],null);
if($CLJS.sd(TT)&&$CLJS.E.g($CLJS.z(TT),$CLJS.aj)){var UT=$CLJS.y(TT);$CLJS.z(UT);var VT=$CLJS.B(UT),ima=$CLJS.td($CLJS.z(VT))?VT:$CLJS.ae(null,VT),WT=$CLJS.y(ima),jma=$CLJS.z(WT),kma=$CLJS.B(WT);ST=$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aj,$CLJS.R.j(jma,$CLJS.ri,1)],null),kma)}else ST=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,TT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ts,"non-empty"],null),$CLJS.y],null)],null);
$CLJS.Y(iM,new $CLJS.P(null,3,5,hma,[$CLJS.kr,ST,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ts,"All join aliases must be unique."],null),function(a){return $CLJS.NK($CLJS.ff($CLJS.Sa,$CLJS.cf.g($CLJS.XD,a)))}],null)],null));var lma=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,iM],null);
$CLJS.Y(mQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.h(null,1,[$CLJS.ts,"Distinct, non-empty sequence of Field clauses"],null),dL(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aj,new $CLJS.h(null,1,[$CLJS.ri,1],null),$CLJS.KR],null))],null));
$CLJS.Y(eP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GP,new $CLJS.h(null,1,[$CLJS.yr,!0],null),OT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dO,new $CLJS.h(null,1,[$CLJS.yr,!0],null),QT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aF,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aj,new $CLJS.h(null,1,[$CLJS.ri,1],null),$CLJS.YR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$E,new $CLJS.h(null,1,
[$CLJS.yr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aj,new $CLJS.h(null,1,[$CLJS.ri,1],null),$CLJS.KR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YO,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ir,lR,$CLJS.qT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),RT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PE,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.QS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iR,new $CLJS.h(null,
1,[$CLJS.yr,!0],null),pR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MQ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),dL(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aj,new $CLJS.h(null,1,[$CLJS.ri,1],null),$la],null))],null),new $CLJS.P(null,3,5,$CLJS.Q,[iP,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[iP,oR],null),new $CLJS.P(null,2,5,$CLJS.Q,[Sja,oR],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CM,new $CLJS.h(null,1,[$CLJS.yr,!0],null),lma],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QN,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aj,PT],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ts,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.E.g(1,$CLJS.D($CLJS.Dl(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GP,$CLJS.dO],null))))}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,
new $CLJS.h(null,1,[$CLJS.ts,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.O(a);a=$CLJS.K.g(b,$CLJS.$E);b=$CLJS.K.g(b,$CLJS.TD);return $CLJS.pd($CLJS.ct.g($CLJS.Wg(a),$CLJS.Wg(b)))}],null)],null));
$CLJS.XT=$CLJS.zg([$CLJS.EF,$CLJS.AO,cN,OM,RN,$Q,xN,$CLJS.ZQ,$CLJS.Ps,wN,ML,TP,PQ,$CLJS.bM,LQ,$M,$CLJS.WN,uN,$CLJS.Gi,$CLJS.tN,uL,$CLJS.yM,$CLJS.sN,vO,$CLJS.tr,$CLJS.Hj,oN],[new $CLJS.h(null,1,[HN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.EF,null,$CLJS.AO,null,$CLJS.Ps,null,$CLJS.tr,null,$CLJS.Hj,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ri,$CLJS.AO,HN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.EF,null,$CLJS.AO,null,$CLJS.ZQ,null,$CLJS.WN,null,$CLJS.Gi,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.Ri,$CLJS.uj,$CLJS.vD,bQ,HN,new $CLJS.Rg(null,new $CLJS.h(null,1,[cN,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ri,dR,$CLJS.vD,bQ,HN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.EF,null,OM,null,$CLJS.Ps,null,$CLJS.Gi,null,uL,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ri,$CLJS.uj,$CLJS.vD,QL,HN,new $CLJS.Rg(null,new $CLJS.h(null,1,[RN,null],null),null)],null),new $CLJS.h(null,1,[HN,new $CLJS.Rg(null,new $CLJS.h(null,1,[$Q,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ri,
dR,$CLJS.vD,hla,HN,new $CLJS.Rg(null,new $CLJS.h(null,1,[xN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ri,$CLJS.AO,HN,new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.ZQ,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ri,dR,HN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.EF,null,OM,null,$CLJS.Ps,null,$CLJS.Gi,null,uL,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ri,dR,$CLJS.vD,QL,HN,new $CLJS.Rg(null,new $CLJS.h(null,1,[wN,null],null),null)],null),new $CLJS.h(null,1,[HN,new $CLJS.Rg(null,
new $CLJS.h(null,1,[ML,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ri,$CLJS.uj,$CLJS.vD,bQ,HN,new $CLJS.Rg(null,new $CLJS.h(null,8,[$CLJS.EF,null,$Q,null,ML,null,TP,null,uN,null,$CLJS.Gi,null,uL,null,$CLJS.Hj,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ri,$CLJS.uj,$CLJS.vD,QL,HN,new $CLJS.Rg(null,new $CLJS.h(null,1,[PQ,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ri,$CLJS.AO,HN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.ZQ,null,$CLJS.bM,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.Ri,$CLJS.uj,$CLJS.vD,QL,HN,new $CLJS.Rg(null,new $CLJS.h(null,1,[LQ,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ri,$CLJS.uj,$CLJS.vD,QL,HN,new $CLJS.Rg(null,new $CLJS.h(null,1,[$M,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ri,$CLJS.AO,HN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.EF,null,$CLJS.AO,null,$CLJS.ZQ,null,$CLJS.WN,null,$CLJS.Gi,null],null),null)],null),new $CLJS.h(null,1,[HN,new $CLJS.Rg(null,new $CLJS.h(null,1,[uN,null],null),null)],null),new $CLJS.h(null,1,[HN,
new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.Gi,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ri,$CLJS.AO,HN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.ZQ,null,$CLJS.tN,null],null),null)],null),new $CLJS.h(null,1,[HN,new $CLJS.Rg(null,new $CLJS.h(null,1,[uL,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ri,$CLJS.AO,HN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.ZQ,null,$CLJS.yM,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ri,$CLJS.AO,HN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.ZQ,
null,$CLJS.sN,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ri,dR,$CLJS.vD,bQ,HN,new $CLJS.Rg(null,new $CLJS.h(null,1,[vO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ri,$CLJS.tr,HN,new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.EF,null,$CLJS.Gi,null,$CLJS.tr,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ri,$CLJS.uj,HN,new $CLJS.Rg(null,new $CLJS.h(null,8,[$CLJS.EF,null,$Q,null,ML,null,TP,null,uN,null,$CLJS.Gi,null,uL,null,$CLJS.Hj,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ri,
dR,$CLJS.vD,QL,HN,new $CLJS.Rg(null,new $CLJS.h(null,1,[oN,null],null),null)],null)]);$CLJS.Y(oQ,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.ts,"valid parameter type"],null)],null),$CLJS.Ng($CLJS.XT)));$CLJS.Y(YL,$CLJS.Wf.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.ts,"valid template tag widget type"],null),$CLJS.cz],null),$CLJS.Ng($CLJS.XT)));
$CLJS.Y(BP,bL(Jja,$CLJS.H(["tag-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,lR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gi,lR],null)],null)],null)])));var YT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,BP],null);$CLJS.Y(xL,bL($CLJS.EE,$CLJS.H(["target",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,$CLJS.KR,YT],null)])));var ZT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,xL],null);$CLJS.Y(sM,bL(rN,$CLJS.H(["target",YT])));
var $T=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,sM],null),mma=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,$CLJS.KR,cL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof ZT?new $CLJS.Cc(function(){return ZT},$CLJS.ld(Hka,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,
1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mP,"metabase/mbql/schema.cljc",21,$CLJS.EE,1,1612,1612,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EE,$CLJS.oI,$CLJS.U($CLJS.oj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,EM,GN],null))],null),$CLJS.Gc,"Schema for a valid dimension clause.",$CLJS.n(ZT)?ZT.H:null])):null));return $CLJS.n(a)?a:mP}(),ZT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hO.h($CLJS.md("undefined"!==typeof $CLJS.eL&&
"undefined"!==typeof fL&&"undefined"!==typeof gL&&"undefined"!==typeof $T?new $CLJS.Cc(function(){return $T},$CLJS.ld(Wka,new $CLJS.h(null,1,[$CLJS.vj,!0],null)),$CLJS.zg([$CLJS.Di,$CLJS.T,$CLJS.Gj,$CLJS.fi,hO,$CLJS.Ai,$CLJS.Si,$CLJS.Pj,dM,$CLJS.ei,$CLJS.Bj,$CLJS.Fj],[$CLJS.ld(NP,new $CLJS.h(null,1,[$CLJS.Bj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eR,"metabase/mbql/schema.cljc",20,rN,1,1615,1615,new $CLJS.P(null,3,5,$CLJS.Q,[rN,
$CLJS.oI,$CLJS.U($CLJS.oj,GN)],null),$CLJS.Gc,"Schema for a valid variable clause.",$CLJS.n($T)?$T.H:null])):null));return $CLJS.n(a)?a:eR}(),$T],null)]))],null),YK=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,eM],null);
$CLJS.Y(eM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ts,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.gD($CLJS.zz($CLJS.hP,$CLJS.HE),$CLJS.Oe($CLJS.gD($CLJS.hP,$CLJS.HE)))],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ts,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.O(b);b=$CLJS.K.g(c,$CLJS.hP);var d=$CLJS.K.g(c,$CLJS.HE);c=$CLJS.K.g(c,$CLJS.Ri);c=c instanceof $CLJS.M?c.S:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TO,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dr,new $CLJS.h(null,1,[$CLJS.ts,"valid Database ID"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.bK],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.V,$CLJS.WJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,$CLJS.HE,$CLJS.hP],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hP,new $CLJS.h(null,1,[$CLJS.yr,!0],null),fma],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HE,new $CLJS.h(null,1,[$CLJS.yr,!0],null),NT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tM,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aj,new $CLJS.P(null,
9,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,cma],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gi,new $CLJS.h(null,1,[$CLJS.yr,!0],null),lR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oI,new $CLJS.h(null,1,[$CLJS.yr,!0],null),mma],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dj,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.yr,!0],null),lR],null),new $CLJS.P(null,3,5,$CLJS.Q,[Pja,new $CLJS.h(null,1,[$CLJS.yr,!0],null),
lR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WM,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.ur],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$J,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,3,5,$CLJS.Q,[Gla,new $CLJS.h(null,1,[$CLJS.yr,!0],null),yR],null)],null)],null)],null),new $CLJS.P(null,
3,5,$CLJS.Q,[Gka,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,3,5,$CLJS.Q,[nO,new $CLJS.h(null,1,[$CLJS.yr,!0],null),pR],null),new $CLJS.P(null,3,5,$CLJS.Q,[XL,new $CLJS.h(null,1,[$CLJS.yr,!0],null),pR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ts,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=
$CLJS.O(a);a=$CLJS.K.g(b,nO);b=$CLJS.K.g(b,XL);return $CLJS.Ra($CLJS.n(a)?b:a)?!0:a>=b}],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[wka,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,3,5,$CLJS.Q,[qla,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,[Lja,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.tr],null)],null),new $CLJS.P(null,
3,5,$CLJS.Q,[uja,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,[Rja,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,[ila,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.tr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[wja,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.tr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[wla,new $CLJS.h(null,1,[$CLJS.yr,!0],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.tr],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gx,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oM,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,18,5,$CLJS.Q,[$CLJS.Dj,$CLJS.ej,Tka,$CLJS.kN,Vja,Qla,Xka,mka,Ika,eka,Mja,ika,fla,uka,Gja,Wja,Pla,Mla],null)],null)],null),new $CLJS.P(null,
3,5,$CLJS.Q,[lla,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,oR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[rka,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,oR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aQ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,Rla],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Hla,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,lR],null)],null),
new $CLJS.P(null,3,5,$CLJS.Q,[tka,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,oR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Fla,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ir,$CLJS.ur,$CLJS.ur],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[nka,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,oR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nN,new $CLJS.h(null,
1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ir,$CLJS.ur,$CLJS.ur],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JJ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.ur],null)],null)],null)],null)],null)],null)),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.ts,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],
null),$CLJS.Oe($CLJS.QN)],null)],null));var aU=$CLJS.XK(YK);(function(){var a=pja();return function(b){if($CLJS.n(aU.h?aU.h(b):aU.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.UK(b);throw $CLJS.Uh($CLJS.iD("Invalid query: {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([c]))])),new $CLJS.h(null,2,[$CLJS.Cx,c,Bka,b],null));}})();