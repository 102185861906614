import { t } from "ttag";

// import { ID_OPTION } from "metabase-lib/parameters/constants"; // CIQ Edit: comment out
import { buildTypedOperatorOptions } from "metabase-lib/parameters/utils/operators";

export function getDashboardParameterSections() {
  return [
    {
      id: "date",
      name: t`Time`,
      description: t`Date range, relative date, time of day, etc.`,
      options: buildTypedOperatorOptions("date", "date", t`Date`),
    },
    /* CIQ EDit: Due to Metabase semantic types, we only allow filtering by Date, Number, and Text / Category */
    // {
    //   id: "location",
    //   name: t`Location`,
    //   description: t`City, State, Country, ZIP code.`,
    //   options: buildTypedOperatorOptions("string", "location", t`Location`),
    // },
    // {
    //   id: "id",
    //   name: t`ID`,
    //   description: t`User ID, Product ID, Event ID, etc.`,
    //   options: [
    //     {
    //       ...ID_OPTION,
    //       sectionId: "id",
    //     },
    //   ],
    // },
    {
      id: "number",
      name: t`Number`,
      description: t`Subtotal, Age, Price, Quantity, etc.`,
      options: buildTypedOperatorOptions("number", "number", t`Number`),
    },
    {
      id: "string",
      name: t`Text or Category`,
      description: t`Name, Rating, Description, etc.`,
      options: buildTypedOperatorOptions("string", "string", t`Text`),
    },
  ].filter(Boolean);
}
