import styled from "@emotion/styled";
import Button from "metabase/core/components/Button";
import { color, alpha } from "metabase/lib/colors";

// NOTE: some of this is duplicated from NotebookCell.jsx
// CIQ EDIT, reduce emphasis on question button, increase emphasis on save
const QuestionButton = styled(Button)`
  background-color: ${props =>
    props.active ? props.color : alpha(props.color, 0.2)};
  color: ${props => (props.active ? "white" : props.color)};
  border: none;
  &:hover {
    background-color: ${color("brand")};
    color: ${color("white")};
  }
  transition: background 300ms linear, border 300ms linear;
  > .Icon {
    opacity: 0.6;
  }
`;
QuestionButton.defaultProps = {
  color: color("brand"),
};

export default QuestionButton;
