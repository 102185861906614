import { t } from "ttag";

import EmptyState from "metabase/components/EmptyState";
import Button from "metabase/core/components/Button";
import Link from "metabase/core/components/Link";
import { useSelector } from "metabase/lib/redux";
import * as Urls from "metabase/lib/urls";
import { getUserIsAdmin } from "metabase/selectors/user";
import type { Dashboard } from "metabase-types/api";

import { Container } from "./DashboardEmptyState.styled";

function QuestionIllustration() {
  return <span className="QuestionCircle">?</span>;
}

interface DashboardEmptyStateProps {
  dashboard: Dashboard;
  isNightMode: boolean;
  addQuestion: () => void;
  closeNavbar: () => void;
}

export function DashboardEmptyState({
  dashboard,
  isNightMode,
  addQuestion,
  closeNavbar,
}: DashboardEmptyStateProps) {
  /* CIQ Edit: add isAdmin */
  const isAdmin = useSelector(getUserIsAdmin);

  return (
    <Container isNightMode={isNightMode} data-testid="dashboard-empty-state">
      <EmptyState
        illustrationElement={<QuestionIllustration />}
        title={t`This dashboard is looking empty.`}
        message={
          <>
            <Button onlyText onClick={addQuestion}>
              {/* CIQ Edit: question -> visualization */}
              {t`Add a saved visualization`}
            </Button>
            {/* CIQ Edit: hide "or as a new one" if not admin */}
            {isAdmin && (
              <>
                {t`, or `}
                <Link
                  variant="brandBold"
                  to={Urls.newQuestion({
                    mode: "notebook",
                    creationType: "custom_question",
                    collectionId: dashboard.collection_id ?? undefined,
                  })}
                  className="text-bold text-brand"
                  onClick={closeNavbar}
                >
                  {t`ask a new one`}
                </Link>
              </>
            )}
          </>
        }
      />
    </Container>
  );
}

interface DashboardEmptyStateWithoutAddPromptProps {
  isNightMode: boolean;
}

export function DashboardEmptyStateWithoutAddPrompt({
  isNightMode,
}: DashboardEmptyStateWithoutAddPromptProps) {
  return (
    <Container isNightMode={isNightMode}>
      <EmptyState title={t`There's nothing here, yet.`} />
    </Container>
  );
}
