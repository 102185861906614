/* eslint-disable react/prop-types */
import { t } from "ttag";

import QuestionButton from "./QuestionButton";

const ReportModelButton = props => (
  <QuestionButton medium icon="table_spaced" labelBreakpoint="sm" {...props} />
);

export function QuestionReportModelWidget({ onEditReportModel, ...props }) {
  return (
    <ReportModelButton
      onClick={onEditReportModel}
      {...props}
    >{t`Report Model`}</ReportModelButton>
  );
}

QuestionReportModelWidget.shouldRender = ({
  question,
  queryBuilderMode,
  isObjectDetail,
  metadata,
  card,
}) => {
  const sourceTable = card?.dataset_query?.query["source-table"];
  const tableName = sourceTable ? metadata?.tables[sourceTable]?.name : "";

  return (
    queryBuilderMode === "view" &&
    tableName &&
    !tableName.startsWith("dataset_") &&
    !tableName.endsWith("system_data") &&
    !isObjectDetail
  );
};
