var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var BK,DK,FK,IK,LK;$CLJS.yK=function(a){return $CLJS.Hh($CLJS.q($CLJS.gB),a,$CLJS.pF)};$CLJS.zK=new $CLJS.M("lib","source-name","lib/source-name",1931622490);$CLJS.AK=new $CLJS.M(null,"column-ref","column-ref",2018188376);BK=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.CK=new $CLJS.M(null,"operators","operators",-2064102509);
DK=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.EK=new $CLJS.M(null,"stage-number","stage-number",-1752729638);FK=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.GK=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.HK=new $CLJS.M(null,"dimensions","dimensions",-254818097);
IK=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.JK=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.KK=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);LK=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.MK=new $CLJS.M(null,"null","null",-180137709);$CLJS.Y(DK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.xD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rF,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.fD],null)],null)],null));$CLJS.Y(LK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,DK,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.BD],null)],null)],null));$CLJS.Y(FK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,$CLJS.lF],null),LK,$CLJS.sD],null));
$CLJS.Y(IK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,$CLJS.lF],null),DK,$CLJS.gK],null));
$CLJS.LF.g($CLJS.lF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,$CLJS.lF],null),DK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,$CLJS.gK,$CLJS.sD],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.wi,function(a){return $CLJS.sd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.XA(a)):null},$CLJS.ts,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.lA,IK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kA,FK],null)],null)],null));$CLJS.rE($CLJS.lF,$CLJS.pF);$CLJS.MD.m(null,$CLJS.lF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.ni,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.$D});$CLJS.yE($CLJS.qA,$CLJS.H([$CLJS.sD]));$CLJS.MD.m(null,$CLJS.qA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.ni,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.$D});
$CLJS.rE($CLJS.qA,$CLJS.pF);$CLJS.Y(BK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.xD,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.sD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.sD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zK,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.sD],null)],null)],null));
$CLJS.LF.g($CLJS.aF,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,$CLJS.aF],null),BK,$CLJS.uj],null));$CLJS.MD.m(null,$CLJS.aF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.ni,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.$D});$CLJS.rE($CLJS.aF,$CLJS.pF);$CLJS.yE($CLJS.JE,$CLJS.H([$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.NJ],null)],null)]));$CLJS.rE($CLJS.JE,$CLJS.pF);
$CLJS.yE($CLJS.gF,$CLJS.H([$CLJS.Zr,$CLJS.$D,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.LJ],null)],null)]));$CLJS.rE($CLJS.gF,$CLJS.pF);
$CLJS.Y($CLJS.pF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,$CLJS.OD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.Es,function(){return["Valid reference, must be one of these clauses: ",$CLJS.hs(", ",$CLJS.Cz($CLJS.Ed,$CLJS.Ak.g($CLJS.q($CLJS.gB),$CLJS.pF)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.yK(a)}],null)],null));