import { useCallback, useLayoutEffect, useState } from "react";
import { t } from "ttag";

import Radio from "metabase/core/components/Radio";
import type { EmbeddingParameterVisibility } from "metabase/public/lib/types";
import { Text, TextInput } from "metabase/ui";
import { canUseCustomSource } from "metabase-lib/parameters/utils/parameter-source";
import type {
  Parameter,
  ValuesQueryType,
  ValuesSourceConfig,
  ValuesSourceType,
} from "metabase-types/api";

import { getIsMultiSelect } from "../../utils/dashboards";
import { isSingleOrMultiSelectable } from "../../utils/parameter-type";
import { RequiredParamToggle } from "../RequiredParamToggle";
import ValuesSourceSettings from "../ValuesSourceSettings";

import {
  SettingLabel,
  SettingLabelError,
  SettingSection,
  SettingsRoot,
  SettingValueWidget,
} from "./ParameterSettings.styled";

export interface ParameterSettingsProps {
  parameter: Parameter;
  isParameterSlugUsed: (value: string) => boolean;
  onChangeName: (name: string) => void;
  onChangeDefaultValue: (value: unknown) => void;
  onChangeIsMultiSelect: (isMultiSelect: boolean) => void;
  onChangeQueryType: (queryType: ValuesQueryType) => void;
  onChangeSourceType: (sourceType: ValuesSourceType) => void;
  onChangeSourceConfig: (sourceConfig: ValuesSourceConfig) => void;
  onChangeRequired: (value: boolean) => void;
  embeddedParameterVisibility: EmbeddingParameterVisibility | null;
  shouldAllowEdit: boolean; // CIQ Edit
}

export const ParameterSettings = ({
  parameter,
  isParameterSlugUsed,
  onChangeName,
  onChangeDefaultValue,
  onChangeIsMultiSelect,
  onChangeQueryType,
  onChangeSourceType,
  onChangeSourceConfig,
  onChangeRequired,
  embeddedParameterVisibility,
  shouldAllowEdit, // CIQ Edit
}: ParameterSettingsProps): JSX.Element => {
  const [tempLabelValue, setTempLabelValue] = useState(parameter.name);

  useLayoutEffect(() => {
    setTempLabelValue(parameter.name);
  }, [parameter.name]);

  const labelError = getLabelError({
    labelValue: tempLabelValue,
    isParameterSlugUsed,
  });

  const handleLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTempLabelValue(event.target.value);
  };

  const handleLabelBlur = (event: { target: HTMLInputElement }) => {
    if (labelError) {
      // revert to the value before editing
      setTempLabelValue(parameter.name);
    } else {
      onChangeName(event.target.value);
    }
  };

  const handleSourceSettingsChange = useCallback(
    (sourceType: ValuesSourceType, sourceConfig: ValuesSourceConfig) => {
      onChangeSourceType(sourceType);
      onChangeSourceConfig(sourceConfig);
    },
    [onChangeSourceType, onChangeSourceConfig],
  );

  const isEmbeddedDisabled = embeddedParameterVisibility === "disabled";
  return (
    <SettingsRoot>
      {/* CIQ Edit: if filter is Employee ID and dash is VBF, don't allow editing filter */}
      {shouldAllowEdit && (
        <SettingSection>
          <SettingLabel>{t`Label`}</SettingLabel>
          <TextInput
            onChange={handleLabelChange}
            value={tempLabelValue}
            onBlur={handleLabelBlur}
            error={labelError}
            aria-label={t`Label`}
          />
        </SettingSection>
      )}
      {shouldAllowEdit ? (
        <>
          {canUseCustomSource(parameter) && (
            <SettingSection>
              <SettingLabel>{t`How should people filter on this column?`}</SettingLabel>
              <ValuesSourceSettings
                parameter={parameter}
                onChangeQueryType={onChangeQueryType}
                onChangeSourceSettings={handleSourceSettingsChange}
              />
            </SettingSection>
          )}

          {isSingleOrMultiSelectable(parameter) && (
            <SettingSection>
              <SettingLabel>{t`People can pick`}</SettingLabel>
              <Radio
                value={getIsMultiSelect(parameter)}
                options={[
                  { name: t`Multiple values`, value: true },
                  { name: t`A single value`, value: false },
                ]}
                vertical
                onChange={onChangeIsMultiSelect}
              />
            </SettingSection>
          )}

          <SettingSection>
            <SettingLabel>
              {t`Default value`}
              {parameter.required && !parameter.default && (
                <SettingLabelError>({t`required`})</SettingLabelError>
              )}
            </SettingLabel>

            <SettingValueWidget
              parameter={parameter}
              name={parameter.name}
              value={parameter.default}
              placeholder={t`No default`}
              setValue={onChangeDefaultValue}
            />

            <RequiredParamToggle
              // This forces the toggle to be a new instance when the parameter changes,
              // so that toggles don't slide, which is confusing.
              key={`required_param_toggle_${parameter.id}`}
              uniqueId={parameter.id}
              disabled={isEmbeddedDisabled}
              value={parameter.required ?? false}
              onChange={onChangeRequired}
              disabledTooltip={
                <>
                  <Text lh={1.4}>
                    {t`This filter is set to disabled in an embedded dashboard.`}
                  </Text>
                  <Text lh={1.4}>
                    {t`To always require a value, first visit embedding settings,
                        make this filter editable or locked, re-publish the
                        dashboard, then return to this page.`}
                  </Text>
                  <Text size="sm">
                    {t`Note`}:{" "}
                    {t`making it locked, will require updating the
                        embedding code before proceeding, otherwise the embed will
                        break.`}
                  </Text>
                </>
              }
            ></RequiredParamToggle>
          </SettingSection>
        </>
      ) : (
        <>
          <div className="mt2">{t`This filter cannot be renamed or removed`}</div>{" "}
        </>
      )}
    </SettingsRoot>
  );
};

function getLabelError({
  labelValue,
  isParameterSlugUsed,
}: {
  labelValue: string;
  isParameterSlugUsed: (value: string) => boolean;
}) {
  if (!labelValue) {
    return t`Required`;
  }
  if (isParameterSlugUsed(labelValue)) {
    return t`This label is already in use.`;
  }
  if (labelValue.toLowerCase() === "tab") {
    return t`This label is reserved for dashboard tabs.`;
  }
  return null;
}
