var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.binning.js");require("./metabase.lib.equality.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.malli.js");
'use strict';var cta,dta;
cta=function(a,b){return function f(d,e){return new $CLJS.ie(null,function(){var k=$CLJS.y(e);if(k){if($CLJS.wd(k)){for(var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m),u=0;;)if(u<m){var v=function(){var x=d+u,A=$CLJS.id(l,u);return a.g?a.g(x,A):a.call(null,x,A)}();null!=v&&t.add(v);u+=1}else break;return $CLJS.oe($CLJS.qe(t),f(d+m,$CLJS.jc(k)))}m=function(){var x=$CLJS.z(k);return a.g?a.g(d,x):a.call(null,d,x)}();return null==m?f(d+1,$CLJS.Hc(k)):$CLJS.ae(m,f(d+1,$CLJS.Hc(k)))}return null},null,null)}(0,
b)};$CLJS.O5=function(a,b){return $CLJS.H1($CLJS.Fq,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null))};$CLJS.P5=function(a,b){return $CLJS.H1($CLJS.Dq,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null))};$CLJS.Q5=function(a,b){return $CLJS.n5.g(a,$CLJS.Dd(b,$CLJS.mD)?$CLJS.mD.h(b):b)};dta=new $CLJS.M(null,"same-temporal-bucket?","same-temporal-bucket?",2110106995);$CLJS.P_.m(null,$CLJS.$E,function(a,b){var c=$CLJS.Be($CLJS.$E.h($CLJS.DV(a,b)));return $CLJS.n(c)?$CLJS.iD("Grouped by {0}",$CLJS.H([$CLJS.hs([" ",$CLJS.p.h($CLJS.KD("and"))," "].join(""),function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.wd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);a:for(var u=0;;)if(u<m){var v=$CLJS.id(l,u);v=$CLJS.l_.v(a,b,v,$CLJS.m_);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),
null)}t=$CLJS.z(k);return $CLJS.ae($CLJS.l_.v(a,b,t,$CLJS.m_),f($CLJS.Hc(k)))}return null}},null,null)}(c)}())])):null});$CLJS.R5=function(){function a(d,e,f){f=$CLJS.oV($CLJS.jd(f)?f.g?f.g(d,e):f.call(null,d,e):f);return $CLJS.M1(d,e,$CLJS.$E,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.S5=function(){function a(d,e){return $CLJS.Be($CLJS.$E.h($CLJS.DV(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.T5=function(){function a(d,e){var f=$CLJS.S5.g(d,e);return null==f?null:$CLJS.hk.g(function(k){return $CLJS.R.j($CLJS.j_.j(d,e,k),$CLJS.MJ,$CLJS.aK)},f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.U5=function(){function a(d,e){var f=function(){var l=$CLJS.DV(d,e);return $CLJS.Y_.v(d,e,l,new $CLJS.h(null,1,[$CLJS.J_,!1],null))}();if($CLJS.y(f)){var k=$CLJS.Wf.g($CLJS.N,cta(function(l,m){var t=$CLJS.V1.M(d,e,m,f,new $CLJS.h(null,1,[$CLJS.S1,!0],null));return $CLJS.n(t)?new $CLJS.P(null,2,5,$CLJS.Q,[t,new $CLJS.P(null,2,5,$CLJS.Q,[l,m],null)],null):null},function(){var l=$CLJS.S5.g(d,e);return $CLJS.n(l)?l:$CLJS.xf}()));return $CLJS.hk.g(function(l){var m=k.h?k.h(l):k.call(null,l),t=$CLJS.I(m,
0,null),u=$CLJS.I(m,1,null);m=$CLJS.i5(u);u=$CLJS.o2(u);u=$CLJS.O(u);u=$CLJS.K.g(u,$CLJS.di);l=$CLJS.R.j(l,$CLJS.K5,!0);l=$CLJS.n(m)?$CLJS.Q5(l,m):l;l=$CLJS.n(u)?$CLJS.h3(l,u):l;return $CLJS.n(t)?$CLJS.R.j(l,$CLJS.v_,t):l},f)}return null}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.V5=function(){function a(d,e,f,k){k=$CLJS.O(k);var l=$CLJS.K.g(k,dta);return $CLJS.Be($CLJS.Wf.j($CLJS.xf,$CLJS.ef(function(m){var t=$CLJS.V1.M(d,e,m,new $CLJS.P(null,1,5,$CLJS.Q,[f],null),new $CLJS.h(null,1,[$CLJS.S1,!0],null));return $CLJS.n(t)?$CLJS.n(l)?$CLJS.E.g($CLJS.o2(m),$CLJS.o2(f)):!0:t}),$CLJS.S5.g(d,e)))}function b(d,e,f){return c.v?c.v(d,e,f,null):c.call(null,d,e,f,null)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,
d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();$CLJS.W5=function(){function a(d,e,f){return $CLJS.cb(function(k,l){return $CLJS.G5.j(k,e,l)},d,$CLJS.V5.j(d,e,f))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();