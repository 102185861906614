import { createSelector } from "@reduxjs/toolkit";
import type { Location } from "history";
/* CIQ Edit */
import _ from "underscore";

/* CIQ Edit: remove unused import */
// import { getUserPersonalCollectionId } from "metabase/selectors/user";
import { canonicalCollectionId } from "metabase/collections/utils";
import * as Urls from "metabase/lib/urls/collections";
import type { Collection, CollectionId } from "metabase-types/api";
import type { State } from "metabase-types/store";

import { ROOT_COLLECTION } from "./constants";

type Props = {
  collectionId?: Collection["id"];
  location?: Location;
  params?: { collectionId?: Collection["id"]; slug?: string };
};

function byCollectionIdProp(state: State, { collectionId }: Props) {
  return collectionId;
}

function byCollectionIdNavParam(state: State, { params }: Props) {
  return params && params.collectionId;
}

function byCollectionUrlId(state: State, { params, location }: Props) {
  const isCollectionPath =
    params &&
    params.slug &&
    location &&
    Urls.isCollectionPath(location.pathname);
  return isCollectionPath && Urls.extractCollectionId(params.slug);
}

function byCollectionQueryParameter(state: State, { location }: Props) {
  return location && location.query && location.query.collectionId;
}

/* CIQ Edit: add fn to omit personal collections from list of collections */
export const omitPersonalCollections = (
  collections: Record<CollectionId, Collection>,
) => {
  return _.filter(collections, c => c.personal_owner_id === null);
};

const getInitialCollectionId = createSelector(
  [
    state => {
      const collections = state.entities.collections || {};
      return collections as Record<CollectionId, Collection>;
    },
    /* CIQ Edit: no personal collections */
    // getUserPersonalCollectionId,

    // these are listed in order of priority
    byCollectionIdProp,
    byCollectionIdNavParam,
    byCollectionUrlId,
    byCollectionQueryParameter,

    // CIQ Edit: default
    () => ROOT_COLLECTION.id,
  ],
  (collections, personalCollectionId, ...collectionIds) => {
    const rootCollectionId = ROOT_COLLECTION.id as CollectionId;
    const allCollectionIds = [
      ...(collectionIds as CollectionId[]),
      rootCollectionId,
    ];

    /* CIQ Edit: no personal collections */
    // if (personalCollectionId) {
    //   allCollectionIds.push(personalCollectionId);
    // }

    for (const collectionId of allCollectionIds) {
      const collection = collections[collectionId];
      if (collection?.can_write) {
        return canonicalCollectionId(collectionId);
      }
    }

    // CIQ Edit: default to first writable collection under root
    // const rootCollection = collections[rootCollectionId];
    // return rootCollection?.can_write
    //   ? canonicalCollectionId(rootCollectionId)
    //   : canonicalCollectionId(personalCollectionId);

    const baseCollection = _.find(
      omitPersonalCollections(collections),
      collection => collection.can_write && collection.location === "/",
    );
    if (baseCollection) {
      return canonicalCollectionId(baseCollection.id);
    }
    return canonicalCollectionId(rootCollectionId);

    /* CIQ Edit End */
  },
);

// eslint-disable-next-line import/no-default-export -- deprecated usage
export default getInitialCollectionId;
